import React from 'react'
import './cartoes.css'

const RelCartoes = () => {
  return (
    <div>
      cartoes
    </div>
  )
}

export default RelCartoes
