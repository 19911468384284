import React from 'react'
import './material.css'

const Material = () => {
  return (
    <div>
      material
    </div>
  )
}

export default Material
