import React from 'react'
import './comparativoPeriodicoLoja.css'

const RelComparativoPeriodicoLoja = () => {
  return (
    <div>
      comparativoPeriodicoLoja
    </div>
  )
}

export default RelComparativoPeriodicoLoja
