import React from 'react'
import './colaboradores.css'

const Colaboradores = () => {
  return (
    <div>
      Colaboradores 
    </div>
  )
}

export default Colaboradores
