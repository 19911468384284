import React from 'react'
import './inventarioRotativo.css'

const InventarioRotativo = () => {
  return (
    <div>
      inventarioRotativo
    </div>
  )
}

export default InventarioRotativo
