import React from 'react'
import './fluxoPequenoCaixa.css'

const RelFluxoPequenoCaixa = () => {
  return (
    <div>
      fluxoPequenoCaixa
    </div>
  )
}

export default RelFluxoPequenoCaixa
