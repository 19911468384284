import React from 'react'
import './dashboardProduto.css';

const DashboardProduto = () => {
  return (
    <div>
      dashboardProduto
    </div>
  )
}

export default DashboardProduto
