import React from 'react'
import './motivoNaoConversao.css'

const MotivoNaoConversao = () => {
  return (
    <div>
      motivoNaoConversao
    </div>
  )
}

export default MotivoNaoConversao
