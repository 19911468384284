import React from 'react'
import './produtos.css'

const Produtos = () => {
  return (
    <div>
      Produtos
    </div>
  )
}

export default Produtos
