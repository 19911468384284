import React from 'react'
import './lancamentoBancario.css'

const LancamentoBancario = () => {
  return (
    <div>
      lancamentoBancario
    </div>
  )
}

export default LancamentoBancario
