import React from 'react'
import './vendaElementoControle.css'

const RelVendaElementoControle = () => {
  return (
    <div>
      vendaElementoControle
    </div>
  )
}

export default RelVendaElementoControle
