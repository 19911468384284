import React from 'react'
import Header from './../../../../componentes/header/header';
import Footer from '../../../../componentes/footer/footer';
import { useParams } from 'react-router-dom';

const ClienteCad = () => {

  const { codCliente } = useParams();

  return (
    <>
     <Header/>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {(codCliente !== '') && (codCliente !== '0') ? `Vamos alterar o cliente ${codCliente}` : 'Vamos inserir um novo cliente'}
    <Footer/>
    </>
  )
}

export default ClienteCad
