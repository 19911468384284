import React from 'react'
import './livroRegistroEntrada.css'

const RelLivroRegistroEntrada = () => {
  return (
    <div>
      livroRegistroEntrada
    </div>
  )
}

export default RelLivroRegistroEntrada
