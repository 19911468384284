import React from 'react'
import './contadores.css'

const Contadores = () => {
  return (
    <div>
      Contadores
    </div>
  )
}

export default Contadores
