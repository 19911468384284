import React from 'react'
import './permissoes.css'

const Permissoes = () => {
  return (
    <div>
      permissoes
    </div>
  )
}

export default Permissoes
