import React from 'react'
import './entradaSaidaMesMes.css';

const EntradaSaidaMesMes = () => {
  return (
    <div>
      entradaSaidaMesMes
    </div>
  )
}

export default EntradaSaidaMesMes
