import React from 'react'
import './modeloEtiqueta.css'

const ModeloEtiqueta = () => {
  return (
    <div>
      modeloEtiqueta
    </div>
  )
}

export default ModeloEtiqueta
