import React from 'react'
import './portador.css'

const Portador = () => {
  return (
    <div>
      portador
    </div>
  )
}

export default Portador
