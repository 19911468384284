import React from 'react'
import './pedidoLoja.css'

const PedidoLoja = () => {
  return (
    <div>
      pedidoLoja
    </div>
  )
}

export default PedidoLoja
