import React from 'react'
import './tabelaPreco.css'

const TabelaPreco = () => {
  return (
    <div>
      TabelaPreco
    </div>
  )
}

export default TabelaPreco
