import React from 'react'
import './metaVenda.css'

const RelMetaVenda = () => {
  return (
    <div>
      metaVenda
    </div>
  )
}

export default RelMetaVenda
