import React from 'react'
import './alteracaoPreco.css';

const AlteracaoPreco = () => {
  return (
    <div>
      alteracaoPreco
    </div>
  )
}

export default AlteracaoPreco
