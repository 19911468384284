import React from 'react'
import './pedidoRankingProduto.css'

const PedidoRankingProduto = () => {
  return (
    <div>
      pedidoRankingProduto
    </div>
  )
}

export default PedidoRankingProduto
