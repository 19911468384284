import React from 'react'
import './footer.css'
import { assets } from '../../assets/assets'

const Footer = () => {
  return (
    <div className='text-center p-4 border-top'>
      <img src={assets.Logo} alt="" width={50} className='me-2'/>
      PexBrasil - SuiteMaximus
    </div>
  )
}

export default Footer
