import React from 'react'
import './relGerencialEvento.css';

const RelGerencialEvento = () => {
  return (
    <div>
      relGerencialEvento
    </div>
  )
}

export default RelGerencialEvento
