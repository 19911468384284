import React from 'react'
import './trocasDevolucoes.css';

const TrocasDevolucoes = () => {
  return (
    <div>
      trocasDevolucoes
    </div>
  )
}

export default TrocasDevolucoes
