import React from 'react'
import './cmvPeriodo.css'

const RelCMVPeriodo = () => {
  return (
    <div>
      cmvPeriodo
    </div>
  )
}

export default RelCMVPeriodo
