import React from 'react'
import './cadProdutoXML.css'

const CadProdutoXML = () => {
  return (
    <div>
      Cadastro de produtos via XML
    </div>
  )
}

export default CadProdutoXML
