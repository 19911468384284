import React from 'react'
import './tituloReceber.css'

const TituloReceber = () => {
  return (
    <div>
      Titulos a receber
    </div>
  )
}

export default TituloReceber
