import React from 'react'
import './emissaoEtiqueta.css'

const EmissaoEtiqueta = () => {
  return (
    <div>
      Emissao de etiquetas
    </div>
  )
}

export default EmissaoEtiqueta
