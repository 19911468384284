import React from 'react'
import './livroApuracaoICMS.css'

const RelLivroApuracaoICMS = () => {
  return (
    <div>
      livroApuracaoICMS
    </div>
  )
}

export default RelLivroApuracaoICMS
