import React from 'react'
import './ajusteApuracaoICMSST.css'

const AjusteApuracaoICMSST = () => {
  return (
    <div>
      ajusteApuracaoICMSST
    </div>
  )
}

export default AjusteApuracaoICMSST
