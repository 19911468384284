import React from 'react'
import './extratoProduto.css';

const ExtratoProduto = () => {
  return (
    <div>
      extrato de produtos
    </div>
  )
}

export default ExtratoProduto
