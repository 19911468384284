import React from 'react'
import './metasLojas.css'

const MetasLojas = () => {
  return (
    <div>
      metasLojas
    </div>
  )
}

export default MetasLojas
