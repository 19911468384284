import React from 'react'
import './receberPedidos.css'

const ReceberPedidos = () => {
  return (
    <div>
      receberPedidos
    </div>
  )
}

export default ReceberPedidos
