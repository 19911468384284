import React from 'react'
import './agendamento.css'

const Agendamento = () => {
  return (
    <div>
      agendamento
    </div>
  )
}

export default Agendamento
