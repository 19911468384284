import React from 'react'
import './geralProduto.css';

const GeralProduto = () => {
  return (
    <div>
      Geral de produtos
    </div>
  )
}

export default GeralProduto
