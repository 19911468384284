import React from 'react'
import './fluxoCaixaResumido.css'

const RelFluxoCaixaResumido = () => {
  return (
    <div>
      fluxoCaixaResumido
    </div>
  )
}

export default RelFluxoCaixaResumido
