import React from 'react'
import './transferencias.css'

const Transferencias = () => {
  return (
    <div>
      transferencias
    </div>
  )
}

export default Transferencias
