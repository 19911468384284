import React from 'react'
import './cancelarNfe.css'

const CancelarNfe = () => {
  return (
    <div>
      cancelarNfe
    </div>
  )
}

export default CancelarNfe
