import React from 'react'
import './eventos.css';

const Eventos = () => {
  return (
    <div>
      Eventos
    </div>
  )
}

export default Eventos
