import React from 'react'
import './relRoyaltiesImportados.css'

const RelRoyaltiesImportados = () => {
  return (
    <div>
      relRoyaltiesImportados
    </div>
  )
}

export default RelRoyaltiesImportados
