import React from 'react'
import './mapaCartoes.css'

const RelMapaCartoes = () => {
  return (
    <div>
      mapaCartoes
    </div>
  )
}

export default RelMapaCartoes
