import React from 'react'
import './planoPontuacao.css';

const PlanoPontuacao = () => {
  return (
    <div>
      planoPontuacao
    </div>
  )
}

export default PlanoPontuacao
