import React from 'react'
import './rastreioProduto.css';

const RastreioProduto = () => {
  return (
    <div>
      Rastreio de produtos
    </div>
  )
}

export default RastreioProduto
