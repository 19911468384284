import React from 'react'
import './desistirBalanco.css'

const DesistirBalanco = () => {
  return (
    <div>
      desistirBalanco
    </div>
  )
}

export default DesistirBalanco
