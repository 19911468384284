import React from 'react'
import './apuracaoInventario.css'

const ApuracaoInventario = () => {
  return (
    <div>
      apuracaoInventario
    </div>
  )
}

export default ApuracaoInventario
