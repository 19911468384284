import React from 'react'
import './lojas.css'

const Lojas = () => {
  return (
    <div>
      Lojas
    </div>
  )
}

export default Lojas
