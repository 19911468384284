import React from 'react'
import './manutencaoNFe.css'

const ManutencaoNFe = () => {
  return (
    <div>
      manutencaoNFe
    </div>
  )
}

export default ManutencaoNFe
