import React from 'react'
import './geralICMS.css'

const RelGeralICMS = () => {
  return (
    <div>
      geralICMS
    </div>
  )
}

export default RelGeralICMS
