import React from 'react'
import './navbar.css'
import { assets } from '../../assets/assets'
import { FaHome } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { useSMContext } from '../../contexto/contexto';
import { MdAdminPanelSettings } from "react-icons/md";
import { AiFillProduct } from "react-icons/ai";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { BsFillPersonVcardFill } from "react-icons/bs";
import { MdOutlinePointOfSale } from "react-icons/md";
import { FaFileInvoice } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const Navbar = (props) => {

  const {nomeUsuario} = useSMContext(); 
  return (
    <nav className='navbar fixed-top navbar-expand-lg navbar-dark bg-primary ps-3 pe-3 cor-navbar'>
      <div className='container-fluid'>
        <Link className='navbar-brand' to="/home">
          <img src={assets.LogoNome}  alt="" />
        </Link>

        <button className='navbar-toggler' type='button' data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
            
            <li className='nav-item'>
              <Link to="/home" className={props.tela === 'home' ? 'btn btn-light me-3 cor-elemento-botao-home' : 'btn btn-outline-light me-3 cor-elemento-botao-home2'} arial-current="page">
                <FaHome className='mb-1 icone-menu'/> <span>Início</span>
              </Link>
            </li>

            <li className='nav-item'>
              <Link to="/mod_administracao" className={props.tela === 'admin' ? 'btn btn-light me-3 cor-elemento-botao-home' : 'btn btn-outline-light me-3 cor-elemento-botao-home2'} arial-current="page">
                <MdAdminPanelSettings className='mb-1 icone-menu'/> <span>Administração</span>
              </Link>
            </li>

            <li className='nav-item'>
              <Link to="/mod_produtos" className={props.tela === 'produto' ? 'btn btn-light me-3 cor-elemento-botao-home' : 'btn btn-outline-light me-3 cor-elemento-botao-home2'} arial-current="page">
                <AiFillProduct className='mb-1 icone-menu'/> <span>Produtos</span>
              </Link>
            </li>

            <li className='nav-item'>
              <Link to="/mod_financeiro" className={props.tela === 'financeiro' ? 'btn btn-light me-3 cor-elemento-botao-home' : 'btn btn-outline-light me-3 cor-elemento-botao-home2'} arial-current="page">
                <FaMoneyCheckDollar className='mb-1 icone-menu'/> <span>Financeiro</span>
              </Link>
            </li>

            <li className='nav-item'>
              <Link to="/mod_crm" className={props.tela === 'crm' ? 'btn btn-light me-3 cor-elemento-botao-home' : 'btn btn-outline-light me-3 cor-elemento-botao-home2'} arial-current="page">
                <BsFillPersonVcardFill className='mb-1 icone-menu' /> <span>CRM</span>
              </Link>
            </li>

            <li className='nav-item'>
              <Link to="/mod_vendas" className={props.tela === 'vendas' ? 'btn btn-light me-3 cor-elemento-botao-home' : 'btn btn-outline-light me-3 cor-elemento-botao-home2'} arial-current="page">
                <MdOutlinePointOfSale className='mb-1 icone-menu'/> <span>Vendas</span>
              </Link>
            </li>

            <li className='nav-item'>
              <Link to="/mod_fiscal" className={props.tela === 'fiscal' ? 'btn btn-light me-3 cor-elemento-botao-home' : 'btn btn-outline-light me-3 cor-elemento-botao-home2'} arial-current="page">
                <FaFileInvoice className='mb-1 icone-menu'/> <span>Fiscal</span>
              </Link>
            </li>

          </ul>

          <ul className='btn-grupo cor-elemento-usuario'>
            <button type="button" className="btn btn-outline-light me-3 dropdown-toggle mt-3" data-bs-toggle="dropdown" aria-expanded="false" >
              <FaUser className='mb-1'/> {nomeUsuario ? nomeUsuario.slice(0, nomeUsuario.indexOf(' ')) : "Entrar"}
            </button>

            <ul className='dropdown-menu dropdown-menu-end'>
              <li><Link to="#" className='dropdown-item'>Meu Perfil</Link></li>
              <li><Link to="#" className='dropdown-item'>Parâmetros</Link></li>
              <li><hr className='dropdown-divider'/></li>
              <li><Link to="#" className='dropdown-item'>Sair</Link></li>
            </ul>
          </ul>


        </div>
      </div>
    </nav>
  )
}

export default Navbar
