import React from 'react'
import './centroCusto.css'

const CentroCusto = () => {
  return (
    <div>
      centroCusto
    </div>
  )
}

export default CentroCusto
