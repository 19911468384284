import React from 'react'
import './relatorioContabilEstoque.css'

const RelatorioContabilEstoque = () => {
  return (
    <div>
      relatorioContabilEstoque
    </div>
  )
}

export default RelatorioContabilEstoque
