import React from 'react'
import './regraNegocio.css'

const RegraNegocio = () => {
  return (
    <div>
      regraNegocio
    </div>
  )
}

export default RegraNegocio
