import React from 'react'
import './tituloPagar.css'

const TituloPagar = () => {
  return (
    <div>
      Titulos a pagar
    </div>
  )
}

export default TituloPagar
