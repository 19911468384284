import {BrowserRouter, Routes, Route} from 'react-router-dom';
import { useSMContext } from '../contexto/contexto';
import Login from '../paginas/login/login';
import NotFound from '../paginas/notFound/notFound';
import Home from './../paginas/home/home';

/* Importação de administração */
import Franqueados from '../paginas/admin/cadastros/franqueados/franqueados';
import Consultores from '../paginas/admin/cadastros/consultores/consultores';
import Lojas from '../paginas/admin/cadastros/lojas/lojas';
import GrupoLojas from '../paginas/admin/cadastros/grupoLojas/grupoLojas';
import Colaboradores from '../paginas/admin/cadastros/colaboradores/colaboradores';
import Fornecedores from './../paginas/admin/cadastros/fornecedores/fornecedores';
import Transportadoras from './../paginas/admin/cadastros/transportadoras/transportadoras';
import RegraNegocio from './../paginas/admin/cadastros/regraNegocio/regraNegocio';
import RegraComissao from './../paginas/admin/cadastros/regraComissao/regraComissao';
import Feriados from './../paginas/admin/cadastros/feriados/feriados';
import ModeloEtiqueta from './../paginas/admin/cadastros/modeloEtiqueta/modeloEtiqueta';
import Permissoes from './../paginas/admin/cadastros/permissoes/permissoes';

import ColaboradorLoja from '../paginas/admin/relatorios/colaboradorLoja/colaboradorLoja';

import CopiarConfigLoja from '../paginas/admin/mov/copiarConfiguracaoLoja/copiarConfigLoja';
import Agendamento from './../paginas/admin/mov/agendamento/agendamento';

/* Importação de produtos */
import Produtos from '../paginas/produtos/cadastros/produtos/produtos';
import Kits from './../paginas/produtos/cadastros/kits/kits';
import GradeTamanho from './../paginas/produtos/cadastros/gradeTamanho/gradeTamanho';
import TabelaPreco from './../paginas/produtos/cadastros/tabelaPreco/tabelaPreco';
import UnidadeMedida from './../paginas/produtos/cadastros/unidade/unidade';
import Cores from './../paginas/produtos/cadastros/cores/cores';
import Artigo from './../paginas/produtos/cadastros/artigo/artigo';
import Material from './../paginas/produtos/cadastros/material/material';
import Modelo from './../paginas/produtos/cadastros/modelo/modelo';
import Linha from './../paginas/produtos/cadastros/linha/linha';
import Colecao from './../paginas/produtos/cadastros/colecao/colecao';
import GrupoProduto from './../paginas/produtos/cadastros/grupoProduto/grupoProduto';

import GeralProduto from '../paginas/produtos/relatorios/geralProduto/geralProduto';
import ExtratoProduto from './../paginas/produtos/relatorios/extratoProduto/extratoProduto';
import RastreioProduto from './../paginas/produtos/relatorios/rastreioProduto/rastreioProduto';
import TrocasDevolucoes from './../paginas/produtos/relatorios/trocasDevolucoes/trocasDevolucoes';
import EntradaSaidaMesMes from './../paginas/produtos/relatorios/entradaSaidaMesMes/entradaSaidaMesMes';
import AlteracaoPreco from './../paginas/produtos/relatorios/alteracaoPreco/alteracaoPreco';
import RelPedidos from './../paginas/produtos/relatorios/pedidos/pedidos';
import GiroProdutos from './../paginas/produtos/relatorios/giroProdutos/giroProdutos';
import RankingProdutos from './../paginas/produtos/relatorios/rankingProdutos/rankingProdutos';
import DashboardProduto from './../paginas/produtos/relatorios/dashboardProduto/dashboardProduto';

import CadProdutoXML from '../paginas/produtos/mov/cadProdutoXML/cadProdutoXML';
import PedidoLoja from './../paginas/produtos/mov/pedidoLoja/pedidoLoja';
import PedidoRankingProduto from './../paginas/produtos/mov/pedidoRankingProduto/pedidoRankingProduto';
import ReceberPedidos from './../paginas/produtos/mov/receberPedidos/receberPedidos';
import IntegrarProdutoECommerce from './../paginas/produtos/mov/integrarProdutoECommerce/integrarProdutoECommerce';

import EntrarBalanco from '../paginas/produtos/inv/entrarBalanco/entrarBalanco';
import GerarArquivoColetor from './../paginas/produtos/inv/gerarArquivoColetor/gerarArquivoColetor';
import ReceberArquivoColetor from './../paginas/produtos/inv/receberArquivoColetor/receberArquivoColetor';
import LimparDadosBalanco from './../paginas/produtos/inv/limparDadosBalanco/limparDadosBalanco';
import DesistirBalanco from './../paginas/produtos/inv/desistirBalanco/desistirBalanco';
import ConcluirBalanco from './../paginas/produtos/inv/concluirBalanco/concluirBalanco';
import AnaliseDivergenciaBalanco from './../paginas/produtos/inv/analiseDivergenciaBalanco/analiseDivergenciaBalanco';
import ApuracaoInventario from './../paginas/produtos/inv/apuracaoInventario/apuracaoInventario';
import InventarioRotativo from './../paginas/produtos/inv/inventarioRotativo/inventarioRotativo';

import EmissaoEtiqueta from '../paginas/produtos/emissaoEtiqueta/emissaoEtiqueta';

/* Importação de financeiro */
import PlanoFinanceiro from '../paginas/financeiro/cadastros/planoFinanceiro/planoFinanceiro';
import Natureza from './../paginas/financeiro/cadastros/natureza/natureza';
import CentroCusto from './../paginas/financeiro/cadastros/centroCusto/centroCusto';
import ContaCorrente from './../paginas/financeiro/cadastros/contaCorrente/contaCorrente';
import PequenoCaixa from './../paginas/financeiro/cadastros/pequenoCaixa/pequenoCaixa';
import PlanoCartao from './../paginas/financeiro/cadastros/planoCartao/planoCartao';
import TipoDocumento from './../paginas/financeiro/cadastros/tipoDocumento/tipoDocumento';
import ModeloRateio from './../paginas/financeiro/cadastros/modeloRateio/modeloRateio';
import AvisoDebito from './../paginas/financeiro/cadastros/avisoDebito/avisoDebito';
import ModeloDRE from './../paginas/financeiro/cadastros/modeloDRE/modeloDRE';
import Portador from './../paginas/financeiro/cadastros/portador/portador';
import ModeloSangria from './../paginas/financeiro/cadastros/modeloSangria/modeloSangria';

import DreFinanceiro from '../paginas/financeiro/relatorios/dreFinanceiro/dreFinanceiro';
import RelTituloPagar from './../paginas/financeiro/relatorios/tituloPagar/tituloPagar';
import RelTituloReceber from './../paginas/financeiro/relatorios/tituloReceber/tituloReceber';
import RelFluxoPequenoCaixa from './../paginas/financeiro/relatorios/fluxoPequenoCaixa/fluxoPequenoCaixa';
import RelFluxoContaCorrente from './../paginas/financeiro/relatorios/fluxoContaCorrente/fluxoContaCorrente';
import RelFluxoCaixaEmpresa from './../paginas/financeiro/relatorios/fluxoCaixaEmpresa/fluxoCaixaEmpresa';
import RelFluxoCaixaResumido from './../paginas/financeiro/relatorios/fluxoCaixaResumido/fluxoCaixaResumido';
import RelCartoes from './../paginas/financeiro/relatorios/cartoes/cartoes';
import RelMapaCartoes from './../paginas/financeiro/relatorios/mapaCartoes/mapaCartoes';
import RelPromissorias from './../paginas/financeiro/relatorios/promissorias/promissorias';
import DashboardFinanceiro from './../paginas/financeiro/relatorios/dashboard/dashboard';

import TituloPagar from '../paginas/financeiro/mov/tituloPagar/tituloPagar';
import TituloReceber from './../paginas/financeiro/mov/tituloReceber/tituloReceber';
import LancamentoBancario from './../paginas/financeiro/mov/lancamentoBancario/lancamentoBancario';
import LancamentoCaixa from './../paginas/financeiro/mov/lancamentoCaixa/lancamentoCaixa';
import Transferencias from './../paginas/financeiro/mov/transferencias/transferencias';
import Promissorias from './../paginas/financeiro/mov/promissorias/promissorias';
import ConciliacaoBancaria from './../paginas/financeiro/mov/conciliacaoBancaria/conciliacaoBancaria';
import ConciliacaoCartao from './../paginas/financeiro/mov/conciliacaoCartao/conciliacaoCartao';

import RegraRoyalties from '../paginas/financeiro/royalties/regraRoyalties/regraRoyalties';
import RelRoyaltiesImportados from '../paginas/financeiro/royalties/relRoyaltiesImportados/relRoyaltiesImportados';
import ComissaoFornecedor from '../paginas/financeiro/royalties/comissaoFornecedor/comissaoFornecedor';
import RelDevolucaoLoja  from '../paginas/financeiro/royalties/relDevolucaoLoja/relDevolucaoLoja';

/* Importação de CRM */
import Clientes from '../paginas/crm/cad/clientes/clientes';
import ClienteCad from '../paginas/crm/cad/clientes/cliente_cad';
import GrupoClientes from '../paginas/crm/cad/grupoClientes/grupoClientes';
import PlanoPontuacao from '../paginas/crm/cad/planoPontuacao/planoPontuacao';
import AcaoSMS from '../paginas/crm/cad/acaoSMS/acaoSMS';

import DashboardCRM from '../paginas/crm/relatorios/dashboard/dashboard';
import RelClienteCompras from '../paginas/crm/relatorios/clienteCompras/clienteCompras';
import RelAniversariantes from '../paginas/crm/relatorios/aniversariantes/aniversariantes';

/* Importação de Vendas */
import ValePresente from '../paginas/vendas/cadastros/valePresente/valePresente';
import MotivoNaoConversao from '../paginas/vendas/cadastros/motivoNaoConversao/motivoNaoConversao';
import MotivoTrocaDevolucao from '../paginas/vendas/cadastros/motivoTrocaDevolucao/motivoTrocaDevolucao';
import MotivoCancelamento from '../paginas/vendas/cadastros/motivoCancelamento/motivoCancelamento';
import Promocoes from '../paginas/vendas/cadastros/promocoes/promocoes';
import MetasLojas from '../paginas/vendas/cadastros/metasLojas/metasLojas';


import ConsultaAtendimentos from '../paginas/vendas/rel/consultaAtendimentos/consultaAtendimentos';
import RelExtratoVendas from '../paginas/vendas/rel/extratoVendas/extratoVendas';
import RelComparativoPeriodicoLoja from '../paginas/vendas/rel/comparativoPeriodicoLoja/comparativoPeriodicoLoja';
import RelVendaElementoControle from '../paginas/vendas/rel/vendaElementoControle/vendaElementoControle';
import RelAcompanhamentoDiarioVenda from '../paginas/vendas/rel/acompanhamentoDiarioVenda/acompanhamentoDiarioVenda';
import RelConferenciaCaixa from '../paginas/vendas/rel/conferenciaCaixa/conferenciaCaixa';
import RelDivergenciaCaixaCego from '../paginas/vendas/rel/divergenciaCaixaCego/divergenciaCaixaCego';
import RelRankingVendedor from '../paginas/vendas/rel/rankingVendedor/rankingVendedor';
import RelRankingFornecedor from '../paginas/vendas/rel/rankingFornecedor/rankingFornecedor';
import RelMetaVenda from '../paginas/vendas/rel/metaVenda/metaVenda';
import RelProdutoSemVenda from '../paginas/vendas/rel/produtoSemVenda/produtoSemVenda';
import RelSangriaSuprimento from '../paginas/vendas/rel/sangriaSuprimento/sangriaSuprimento';
import RelAnaliseGeralLoja from '../paginas/vendas/rel/analiseGeralLoja/analiseGeralLoja';
import DashboardVendas from '../paginas/vendas/rel/dashboard/dashboard';

import EmissaoNfe from '../paginas/vendas/mov/emissaoNfe/emissaoNfe';
import CancelarNfe from '../paginas/vendas/mov/cancelarNfe/cancelarNfe';

import Eventos from '../paginas/vendas/evento/evento/eventos';
import RelPedidoDistribuicao from '../paginas/vendas/evento/relPedidoDistribuicao/relPedidoDistribuicao';
import RelAcompanhamentoEvento from '../paginas/vendas/evento/acompanhamentoEvento/acompanhamentoEvento';
import RelGerencialEvento from '../paginas/vendas/evento/relGerencialEvento/relGerencialEvento';

/* Importação de Fiscal */
import Contadores from '../paginas/fiscal/cadastros/contadores/contadores';
import EspecieVolume from '../paginas/fiscal/cadastros/especieVolume/especieVolume';
import AliquotaIcmsCfop from '../paginas/fiscal/cadastros/aliquotaIcmsCfop/aliquotaIcmsCfop';

import ConsultaDfe from '../paginas/fiscal/rel/consultaDfe/consultaDfe';
import RelLivroRegistroEntrada from '../paginas/fiscal/rel/livroRegistroEntrada/livroRegistroEntrada';
import RelLivroRegistroSaida from '../paginas/fiscal/rel/livroRegistroSaida/livroRegistroSaida';
import RelLivroApuracaoICMS from '../paginas/fiscal/rel/livroApuracaoICMS/livroApuracaoICMS';
import RelatorioContabilEstoque from '../paginas/fiscal/rel/relatorioContabilEstoque/relatorioContabilEstoque';
import RelGeralICMS from '../paginas/fiscal/rel/geralICMS/geralICMS';
import RelResumoCFOP from '../paginas/fiscal/rel/resumoCFOP/resumoCFOP';
import RelPossiveisDuplicidades from '../paginas/fiscal/rel/possiveisDuplicidades/possiveisDuplicidades';
import RelCMVPeriodo from '../paginas/fiscal/rel/cmvPeriodo/cmvPeriodo';

import ManutencaoNFe from '../paginas/fiscal/mov/manutencaoNFe/manutencaoNFe';
import InutilizarNumeroNFe from '../paginas/fiscal/mov/inutilizarNumeroNFe/inutilizarNumeroNFe';
import AjusteApuracaoICMS from '../paginas/fiscal/mov/ajusteApuracaoICMS/ajusteApuracaoICMS';
import AjusteApuracaoICMSST from '../paginas/fiscal/mov/ajusteApuracaoICMSST/ajusteApuracaoICMSST';
import ObrigacoesICMSRecolher from '../paginas/fiscal/mov/obrigacoesICMSRecolher/obrigacoesICMSRecolher';
import ObrigacoesICMSRecolherST from '../paginas/fiscal/mov/obrigacoesICMSRecolherST/obrigacoesICMSRecolherST';
import GerarArquivoSPED from '../paginas/fiscal/mov/gerarArquivoSPED/gerarArquivoSPED';


const Rotas = () =>{

  /* Isso aqui garante que se o cabra não estiver logado, ele é enviado para a tela de login */
  const Private = ({ Item }) => {
    const {codUsuario} = useSMContext();

    return (codUsuario && (codUsuario !== '')) ? <Item /> : <Login />;
  };

  return(
  <>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login/>}/>

        <Route path="/home" element={<Private Item={Home} />} />

        {/* Rotas do módulo administração */}        
        <Route path="/admin/cad/franqueados" element={<Private Item={Franqueados} />} />
        <Route path="/admin/cad/consultores" element={<Private Item={Consultores} />} />
        <Route path="/admin/cad/lojas" element={<Private Item={Lojas} />} />
        <Route path="/admin/cad/grupo_lojas" element={<Private Item={GrupoLojas} />} />
        <Route path="/admin/cad/colaboradores" element={<Private Item={Colaboradores} />} />
        <Route path="/admin/cad/fornecedores" element={<Private Item={Fornecedores} />} />
        <Route path="/admin/cad/transportadoras" element={<Private Item={Transportadoras} />} />
        <Route path="/admin/cad/regra_negocio" element={<Private Item={RegraNegocio} />} />
        <Route path="/admin/cad/regra_comissao" element={<Private Item={RegraComissao} />} />
        <Route path="/admin/cad/feriados" element={<Private Item={Feriados} />} />
        <Route path="/admin/cad/modelo_etiqueta" element={<Private Item={ModeloEtiqueta} />} />
        <Route path="/admin/cad/permissoes" element={<Private Item={Permissoes} />} />

        <Route path="/admin/rel/colaborador_loja" element={<Private Item={ColaboradorLoja} />} />
        
        <Route path="/admin/mov/copiar_config_loja" element={<Private Item={CopiarConfigLoja} />} />
        <Route path="/admin/mov/agendamento" element={<Private Item={Agendamento} />} />
        
        
        {/* Rotas do módulo de produtos */}        
        <Route path="/prod/cad/produtos" element={<Private Item={Produtos} />} />
        <Route path="/prod/cad/kits" element={<Private Item={Kits} />} />
        <Route path="/prod/cad/grade_tamanho" element={<Private Item={GradeTamanho} />} />
        <Route path="/prod/cad/tabela_preco" element={<Private Item={TabelaPreco} />} />
        <Route path="/prod/cad/unidade_medida" element={<Private Item={UnidadeMedida} />} />
        <Route path="/prod/cad/cores" element={<Private Item={Cores} />} />
        <Route path="/prod/cad/artigo" element={<Private Item={Artigo} />} />
        <Route path="/prod/cad/material" element={<Private Item={Material} />} />
        <Route path="/prod/cad/modelo" element={<Private Item={Modelo} />} />
        <Route path="/prod/cad/linha" element={<Private Item={Linha} />} />
        <Route path="/prod/cad/colecao" element={<Private Item={Colecao} />} />
        <Route path="/prod/cad/grupo_produto" element={<Private Item={GrupoProduto} />} />
        
        <Route path="/prod/rel/geral_produto" element={<Private Item={GeralProduto} />} />
        <Route path="/prod/rel/extrato_produto" element={<Private Item={ExtratoProduto} />} />
        <Route path="/prod/rel/rastreio_produto" element={<Private Item={RastreioProduto} />} />
        <Route path="/prod/rel/troca_devolucao" element={<Private Item={TrocasDevolucoes} />} />
        <Route path="/prod/rel/entrada_saida_mes_mes" element={<Private Item={EntradaSaidaMesMes} />} />
        <Route path="/prod/rel/alteracao_preco" element={<Private Item={AlteracaoPreco} />} />
        <Route path="/prod/rel/pedidos" element={<Private Item={RelPedidos} />} />
        <Route path="/prod/rel/giro_produto" element={<Private Item={GiroProdutos} />} />
        <Route path="/prod/rel/ranking_produto" element={<Private Item={RankingProdutos} />} />
        <Route path="/prod/rel/dashboard" element={<Private Item={DashboardProduto} />} />
        
        <Route path="/prod/mov/cadastro_produto_xml" element={<Private Item={CadProdutoXML} />} />
        <Route path="/prod/mov/pedido_loja" element={<Private Item={PedidoLoja} />} />
        <Route path="/prod/mov/pedido_ranking_produto" element={<Private Item={PedidoRankingProduto} />} />
        <Route path="/prod/mov/receber_pedido" element={<Private Item={ReceberPedidos} />} />
        <Route path="/prod/mov/integrar_produto_commerce" element={<Private Item={IntegrarProdutoECommerce} />} />


        <Route path="/prod/inv/entrar_balanco" element={<Private Item={EntrarBalanco} />} />
        <Route path="/prod/inv/gerar_arquivo_coletor" element={<Private Item={GerarArquivoColetor} />} />
        <Route path="/prod/inv/receber_arquivo_coletor" element={<Private Item={ReceberArquivoColetor} />} />
        <Route path="/prod/inv/limpar_dados_balanco" element={<Private Item={LimparDadosBalanco} />} />
        <Route path="/prod/inv/desistir_balanco" element={<Private Item={DesistirBalanco} />} />
        <Route path="/prod/inv/concluir_balanco" element={<Private Item={ConcluirBalanco} />} />
        <Route path="/prod/inv/analise_divergencia_balanco" element={<Private Item={AnaliseDivergenciaBalanco} />} />
        <Route path="/prod/inv/apuracao_inventario" element={<Private Item={ApuracaoInventario} />} />
        <Route path="/prod/inv/inventario_rotativo" element={<Private Item={InventarioRotativo} />} />

        <Route path="/prod/emissao_etiqueta" element={<Private Item={EmissaoEtiqueta} />} />
        

        {/* Rotas do módulo financeiro */}        
        <Route path="/financ/cad/plano_financeiro" element={<Private Item={PlanoFinanceiro} />} />
        <Route path="/financ/cad/natureza" element={<Private Item={Natureza} />} />
        <Route path="/financ/cad/centro_custo" element={<Private Item={CentroCusto} />} />
        <Route path="/financ/cad/conta_corrente" element={<Private Item={ContaCorrente} />} />
        <Route path="/financ/cad/pequeno_caixa" element={<Private Item={PequenoCaixa} />} />
        <Route path="/financ/cad/plano_cartao" element={<Private Item={PlanoCartao} />} />
        <Route path="/financ/cad/tipo_documento" element={<Private Item={TipoDocumento} />} />
        <Route path="/financ/cad/modelo_rateio" element={<Private Item={ModeloRateio} />} />
        <Route path="/financ/cad/aviso_debito" element={<Private Item={AvisoDebito} />} />
        <Route path="/financ/cad/modelo_dre" element={<Private Item={ModeloDRE} />} />
        <Route path="/financ/cad/portador" element={<Private Item={Portador} />} />
        <Route path="/financ/cad/modelo_sangria" element={<Private Item={ModeloSangria} />} />
        
        <Route path="/financ/rel/dre_financeiro" element={<Private Item={DreFinanceiro} />} />
        <Route path="/financ/rel/titulo_pagar" element={<Private Item={RelTituloPagar} />} />
        <Route path="/financ/rel/titulo_receber" element={<Private Item={RelTituloReceber} />} />
        <Route path="/financ/rel/fluxo_pequeno_caixa" element={<Private Item={RelFluxoPequenoCaixa} />} />
        <Route path="/financ/rel/fluxo_conta_corrente" element={<Private Item={RelFluxoContaCorrente} />} />
        <Route path="/financ/rel/fluxo_caixa_empresa" element={<Private Item={RelFluxoCaixaEmpresa} />} />
        <Route path="/financ/rel/fluxo_caixa_resumido" element={<Private Item={RelFluxoCaixaResumido} />} />
        <Route path="/financ/rel/cartoes" element={<Private Item={RelCartoes} />} />
        <Route path="/financ/rel/mapa_cartoes" element={<Private Item={RelMapaCartoes} />} />
        <Route path="/financ/rel/promissorias" element={<Private Item={RelPromissorias} />} />
        <Route path="/financ/rel/dashboard" element={<Private Item={DashboardFinanceiro} />} />

        <Route path="/financ/mov/titulo_pagar" element={<Private Item={TituloPagar} />} />
        <Route path="/financ/mov/titulo_receber" element={<Private Item={TituloReceber} />} />
        <Route path="/financ/mov/lancamento_bancario" element={<Private Item={LancamentoBancario} />} />
        <Route path="/financ/mov/lancamento_caixa" element={<Private Item={LancamentoCaixa} />} />
        <Route path="/financ/mov/transferencias" element={<Private Item={Transferencias} />} />
        <Route path="/financ/mov/promissorias" element={<Private Item={Promissorias} />} />
        <Route path="/financ/mov/conciliacao_cartoes" element={<Private Item={ConciliacaoCartao} />} />
        <Route path="/financ/mov/conciliacao_bancaria" element={<Private Item={ConciliacaoBancaria} />} />

        <Route path="/financ/roy/regra_royalties" element={<Private Item={RegraRoyalties} />} />
        <Route path="/financ/roy/rel_royalties_importado" element={<Private Item={RelRoyaltiesImportados} />} />
        <Route path="/financ/roy/comissao_fornecedor" element={<Private Item={ComissaoFornecedor} />} />
        <Route path="/financ/roy/devolucao_loja" element={<Private Item={RelDevolucaoLoja} />} />
        
        {/* Rotas do módulo CRM */}        
        <Route path="/crm/cad/clientes" element={<Private Item={Clientes} />} />
        <Route path="/crm/cad/clientes_cad/:codCliente" element={<Private Item={ClienteCad} />} />
        <Route path="/crm/cad/grupo_cliente" element={<Private Item={GrupoClientes} />} />
        <Route path="/crm/cad/plano_pontuacao" element={<Private Item={PlanoPontuacao} />} />
        <Route path="/crm/cad/acao_sms" element={<Private Item={AcaoSMS} />} />
        
        <Route path="/crm/rel/dashboard" element={<Private Item={DashboardCRM} />} />
        <Route path="/crm/rel/clientes_compra" element={<Private Item={RelClienteCompras} />} />
        <Route path="/crm/rel/aniversariantes" element={<Private Item={RelAniversariantes} />} />
        
        {/* Rotas do módulo de vendas */}        
        <Route path="/vendas/cad/vale_presente" element={<Private Item={ValePresente} />} />
        <Route path="/vendas/cad/motivo_nao_conversao" element={<Private Item={MotivoNaoConversao} />} />
        <Route path="/vendas/cad/motivo_troca_devolucao" element={<Private Item={MotivoTrocaDevolucao} />} />
        <Route path="/vendas/cad/motivo_cancelamento" element={<Private Item={MotivoCancelamento} />} />
        <Route path="/vendas/cad/promocao" element={<Private Item={Promocoes} />} />
        <Route path="/vendas/cad/meta_loja" element={<Private Item={MetasLojas} />} />

        <Route path="/vendas/rel/consulta_atendimentos" element={<Private Item={ConsultaAtendimentos} />} />
        <Route path="/vendas/rel/conferencia_caixa" element={<Private Item={RelConferenciaCaixa} />} />
        <Route path="/vendas/rel/divergencia_caixa_cego" element={<Private Item={RelDivergenciaCaixaCego} />} />
        <Route path="/vendas/rel/ranking_vendedor" element={<Private Item={RelRankingVendedor} />} />
        <Route path="/vendas/rel/ranking_fornecedor" element={<Private Item={RelRankingFornecedor} />} />
        <Route path="/vendas/rel/extrato_vendas" element={<Private Item={RelExtratoVendas} />} />
        <Route path="/vendas/rel/comparativo_periodico_loja" element={<Private Item={RelComparativoPeriodicoLoja} />} />
        <Route path="/vendas/rel/venda_elemento_controle" element={<Private Item={RelVendaElementoControle} />} />
        <Route path="/vendas/rel/acompanhamento_diario_venda" element={<Private Item={RelAcompanhamentoDiarioVenda} />} />
        <Route path="/vendas/rel/meta_venda" element={<Private Item={RelMetaVenda} />} />
        <Route path="/vendas/rel/produto_sem_venda" element={<Private Item={RelProdutoSemVenda} />} />
        <Route path="/vendas/rel/sangria_suprimento" element={<Private Item={RelSangriaSuprimento} />} />
        <Route path="/vendas/rel/analise_geral_loja" element={<Private Item={RelAnaliseGeralLoja} />} />
        <Route path="/vendas/rel/dashboard" element={<Private Item={DashboardVendas} />} />
        
        <Route path="/vendas/mov/emissao_nfe" element={<Private Item={EmissaoNfe} />} />
        <Route path="/vendas/mov/cancelar_nfe_vendas" element={<Private Item={CancelarNfe} />} />

        <Route path="/vendas/eventos/eventos" element={<Private Item={Eventos} />} />
        <Route path="/vendas/eventos/rel_pedido_distribuicao" element={<Private Item={RelPedidoDistribuicao} />} />
        <Route path="/vendas/eventos/acompanhamento_evento" element={<Private Item={RelAcompanhamentoEvento} />} />
        <Route path="/vendas/eventos/rel_gerencial_evento" element={<Private Item={RelGerencialEvento} />} />

        {/* Rotas do módulo fiscal */}        
        <Route path="/fiscal/cad/contadores" element={<Private Item={Contadores} />} />
        <Route path="/fiscal/cad/especie_volume" element={<Private Item={EspecieVolume} />} />
        <Route path="/fiscal/cad/aliquota_icms_cfop" element={<Private Item={AliquotaIcmsCfop} />} />

        <Route path="/fiscal/rel/consulta_dfe" element={<Private Item={ConsultaDfe} />} />
        <Route path="/fiscal/rel/livro_registro_entrada" element={<Private Item={RelLivroRegistroEntrada} />} />
        <Route path="/fiscal/rel/livro_registro_saida" element={<Private Item={RelLivroRegistroSaida} />} />
        <Route path="/fiscal/rel/livro_apuracao_icms" element={<Private Item={RelLivroApuracaoICMS} />} />
        <Route path="/fiscal/rel/relatorio_contabil_estoque" element={<Private Item={RelatorioContabilEstoque} />} />
        <Route path="/fiscal/rel/geral_icms" element={<Private Item={RelGeralICMS} />} />
        <Route path="/fiscal/rel/resumo_cfop" element={<Private Item={RelResumoCFOP} />} />
        <Route path="/fiscal/rel/possiveis_duplicidades" element={<Private Item={RelPossiveisDuplicidades} />} />
        <Route path="/fiscal/rel/cmv_periodo" element={<Private Item={RelCMVPeriodo} />} />
        
        <Route path="/fiscal/mov/emissao_nfe" element={<Private Item={EmissaoNfe} />} />
        <Route path="/fiscal/mov/manutencao_nfe" element={<Private Item={ManutencaoNFe} />} />
        <Route path="/fiscal/mov/inutilizar_numero_nfe" element={<Private Item={InutilizarNumeroNFe} />} />
        <Route path="/fiscal/mov/ajuste_apuracao_icms" element={<Private Item={AjusteApuracaoICMS} />} />
        <Route path="/fiscal/mov/ajuste_apuracao_icms_st" element={<Private Item={AjusteApuracaoICMSST} />} />
        <Route path="/fiscal/mov/obrigacoes_icms_recolher" element={<Private Item={ObrigacoesICMSRecolher} />} />
        <Route path="/fiscal/mov/obrigacoes_icms_recolher_st" element={<Private Item={ObrigacoesICMSRecolherST} />} />
        <Route path="/fiscal/mov/gerar_arquivo_sped" element={<Private Item={GerarArquivoSPED} />} />
        

        <Route path='*' element={<NotFound/>}/>
      </Routes>
    </BrowserRouter>
  </>
  )
}

export default Rotas;