import React from 'react'
import './grupoProduto.css'

const GrupoProduto = () => {
  return (
    <div>
      grupoProduto
    </div>
  )
}

export default GrupoProduto
