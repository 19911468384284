import React from 'react'
import './entrarBalanco.css'

const EntrarBalanco = () => {
  return (
    <div>
      Entrar em modo de balanço
    </div>
  )
}

export default EntrarBalanco
