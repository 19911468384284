import React, { createContext, useState, useContext } from 'react';

const SMContext = createContext();

export const SMProvider = ({children}) => {
  /* Aqui vamos manter sempre os dados salvos para que o usuário não precise entrar com seus dados de novo. 
     A não ser que ele clique em deslogar */
  const [codUsuario, setCodUsuario] = useState(localStorage.getItem('cod_usuario_sm'));
  const [nomeUsuario, setNomeUsuario] = useState(localStorage.getItem('nome_usuario_sm'));
  const [loginUsuario, setLoginUsuario] = useState(localStorage.getItem('login_usuario_sm'));
  const [codFranqueado, setCodFranqueado] = useState(localStorage.getItem('cod_franqueado'));
  const [hostServidor, setHostServidor] = useState(localStorage.getItem('host_servidor'));
  const [portaServidor, setPortaServidor] = useState(localStorage.getItem('porta_servidor'));
  const [codClientePex, setCodClientePex] = useState(localStorage.getItem('cod_cliente_pex'));
  const [lojas, setLojas] = useState([]);

  const [descricaoArtigo, setDescricaoArtigo] = useState('');
  const [descricaoArtigoPlural, setDescricaoArtigoPlural] = useState('');

  const [descricaoMaterial, setDescricaoMaterial] = useState('');
  const [descricaoMaterialPlural, setDescricaoMaterialPlural] = useState('');

  const [descricaoModelo, setDescricaoModelo] = useState('');
  const [descricaoModeloPlural, setDescricaoModeloPlural] = useState('');
  
  const [descricaoLinha, setDescricaoLinha] = useState('');
  const [descricaoLinhaPlural, setDescricaoLinhaPlural] = useState('');

  const [descricaoColecao, setDescricaoColecao] = useState('');
  const [descricaoColecaoPlural, setDescricaoColecaoPlural] = useState('');
  
  const [descricaoGrupoProduto, setDescricaoGrupoProduto] = useState('');
  const [descricaoGrupoProdutoPlural, setDescricaoGrupoProdutoPlural] = useState('');
  
  return (
    <SMContext.Provider value={{codUsuario, setCodUsuario,
                                loginUsuario, setLoginUsuario,
                                nomeUsuario, setNomeUsuario,
                                hostServidor, setHostServidor,
                                portaServidor, setPortaServidor,
                                codFranqueado, setCodFranqueado,
                                codClientePex, setCodClientePex,
                                descricaoArtigo, setDescricaoArtigo,
                                descricaoArtigoPlural, setDescricaoArtigoPlural,
                                descricaoMaterial, setDescricaoMaterial,
                                descricaoMaterialPlural, setDescricaoMaterialPlural,
                                descricaoModelo, setDescricaoModelo,
                                descricaoModeloPlural, setDescricaoModeloPlural,
                                descricaoLinha, setDescricaoLinha,
                                descricaoLinhaPlural, setDescricaoLinhaPlural,
                                descricaoColecao, setDescricaoColecao,
                                descricaoColecaoPlural, setDescricaoColecaoPlural,
                                descricaoGrupoProduto, setDescricaoGrupoProduto,
                                descricaoGrupoProdutoPlural, setDescricaoGrupoProdutoPlural,
                                lojas, setLojas}}>
      {children}
    </SMContext.Provider>
  );
};

export const useSMContext = () => useContext(SMContext);
