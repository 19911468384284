import React from 'react'
import './pedidos.css';

const RelPedidos = () => {
  return (
    <div>
      pedidos
    </div>
  )
}

export default RelPedidos
