import React from 'react'
import './dashboard.css'

const DashboardVendas = () => {
  return (
    <div>
      dashboard
    </div>
  )
}

export default DashboardVendas
