import React from 'react'
import './motivoTrocaDevolucao.css'

const MotivoTrocaDevolucao = () => {
  return (
    <div>
      motivoTrocaDevolucao
    </div>
  )
}

export default MotivoTrocaDevolucao
