import React from 'react'
import './grupoClientes.css';

const GrupoClientes = () => {
  return (
    <div>
      grupoClientes
    </div>
  )
}

export default GrupoClientes
