import React from 'react'
import './motivoCancelamento.css'

const MotivoCancelamento = () => {
  return (
    <div>
      motivoCancelamento
    </div>
  )
}

export default MotivoCancelamento
