import React from 'react'
import './sangriaSuprimento.css'

const RelSangriaSuprimento = () => {
  return (
    <div>
      sangriaSuprimento
    </div>
  )
}

export default RelSangriaSuprimento
