import React from 'react'
import './livroRegistroSaida.css'

const RelLivroRegistroSaida = () => {
  return (
    <div>
      livroRegistroSaida
    </div>
  )
}

export default RelLivroRegistroSaida
