import React, {useEffect, useState} from 'react'
import './clientes.css';
import Header from './../../../../componentes/header/header';
import Footer from '../../../../componentes/footer/footer';
import { assets } from '../../../../assets/assets';
import { Link } from 'react-router-dom';
import { GerarListagemCliente } from '../../../../componentes/pdf/pdf_pex';
import Loading from '../../../../componentes/loading/loading';
import { useNavigate } from 'react-router-dom'
import { useSMContext } from '../../../../contexto/contexto';
import axios from 'axios';
import '../../../paginas.css'
import DialogoPex from '../../../../componentes/dialogo/dialogo';
import { mask as masker } from "../../../../componentes/mascara/mascara.jsx";
import Paginacao from '../../../../componentes/paginacao/paginacao.jsx';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const Clientes = () => {
  
  /* Serve para controlar o loading se ele deve ser mostrado ou não */ 
  const [loading, setLoading] = useState(false);

  /* Definição de paginação */
  const [offset, setOffset] = useState(0);

  /* Pega contextos salvos que são necessários na tela */
  const {codClientePex, hostServidor, portaServidor, loginUsuario} = useSMContext(); 

  /* Armazena o código do item a ser apagado e o outro se deve ou nao mostrar a pergunta de confirmação */
  const [confirmacaoId, setConfirmacaoId] = useState('');
  const [mostrarDelete, setMostrarDelete] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [confirmacaoErro, setConfirmacaoErro] = useState('');
  const [listaClientes, setListaClientes] = useState([]);
  const [nomepesquisa, setNomePesquisa] = useState('');
  const [totalRegistros, setTotalRegistros] = useState(0);

  const navigate = useNavigate();


  const InserirNovoCliente = (e) => {
    navigate('/crm/cad/clientes_cad/0');
  }


  useEffect(() => {
    PesquisarCliente();

  }, [offset]);



  useEffect(() => { 
    const handleScroll = () => { 
      const position = window.pageYOffset || document.documentElement.scrollTop; 
      setScrollPosition(position); 
    }; 
    
    window.addEventListener('scroll', handleScroll); 
    return () => { 
      window.removeEventListener('scroll', handleScroll); 
    }; 
  }, []); 

  //Rotina que apaga o cliente
  const ApagarCliente = async (id) => {
    setMostrarDelete(false);
    try {
      const response = await axios.delete('http://' + hostServidor + ':' + portaServidor + '/web/v1/apagarCliente', {params: {cod_cliente:id, usuario: loginUsuario}});
      if (response.status !== 200){
        setConfirmacaoErro("Houve um erro: " + response.data.mensagem_erro);
      }
      else{
        navigate('/crm/cad/clientes');
        PesquisarCliente();
      }
    } catch (error) {
      setConfirmacaoErro("Houve um erro no cacth: " + error.msg);  
    }
  }


  //Rotina que chama a tela de pergunta se Ã© pra apagar ou nÃ£o
  function ConfirmarApagarCliente(id){
    setConfirmacaoId(id);
    setMostrarDelete(true);
  }

  //Traz os dados do cliente
  const PesquisarCliente = async () =>{
    setLoading("S");
    try {
      const response = await axios.get('http://' + hostServidor + ':' + portaServidor + "/web/v1/obterDadosCliente", 
                       {params: {cod_cliente_pex: codClientePex, nome: nomepesquisa, pagina: offset}});
      setLoading('N');
      if (response.status !== 200){
        setConfirmacaoErro("Houve um erro: " + response.data.mensagem_erro);
      }else{
        setListaClientes(response.data.clientes);
        setTotalRegistros(response.data.total_registros);
      }

    } catch (error) {
      setLoading('N');
      alert(error);
    }
  }

return (
    <>
      <Header/>
      
      <div className="container-md">

        <div className="container-cadpex">

          <div className="titulo-listagem-cadastro-pex">
            Clientes
          </div>

          <div className='header-cadpex'>
           
            <div className="search-cadpex">
              <input type="text" onChange={(e) => setNomePesquisa(e.target.value)} placeholder="Pesquisar" />
              <button onClick={(e) => PesquisarCliente()} ><img src={assets.Pesquisar} alt="Pesquisar" /></button>
            </div>

            <div className="button-container">
              <button className="botao-inserir" onClick={(e) => InserirNovoCliente()}>Inserir Novo</button>
              <button className="botao-pdf" onClick={(e) => GerarListagemCliente(listaClientes)}><img src={assets.LogoPDF} alt="Salvar PDF" />Salvar PDF</button>
            </div>
          </div>          
        </div>          

        {loading === 'S' ? ( <Loading /> ) :
          <table className="table table-hover">
            <thead className="table-header-pex">
              <tr>
                <th className="titulo-pex col-2 table-left" scope="col">CPF/CNPJ</th>
                <th className="titulo-pex col-5 table-left" scope="col">Nome</th>
                <th className="titulo-pex col table-left" scope="col">Celular</th>
                <th className="titulo-pex col table-center" scope="col">Nascimento</th>
                <th className="titulo-pex col-2 table-right" scope="col">#</th>
                <th className="titulo-pex col table-right" scope="col"></th>
              </tr>
            </thead>

            <tbody>
              {
                Array.isArray(listaClientes) && listaClientes?.map((cliente) => (
                  <tr key={cliente.cod_pessoa}>
                    <td className='table-left'>{masker(cliente.cnpj, ['999.999.999-99', '99.999.999/9999-99'])}</td>
                    <td className='table-left'>{cliente.fantasia}</td>
                    <td className='table-left'>{cliente.celular}</td>
                    <td className='table-center'>{cliente.data_nascimento}</td>
                    <td className='table-right'>{cliente.cod_pessoa.slice(4)}</td>
                    <td className='coluna-acao'>
                      <Link to={'/crm/cad/clientes_cad/' + cliente.cod_pessoa}><img className="acoes-grid-pex" src={assets.BotaoAlterar} alt="Alterar"/></Link>
                      <Link to='#' onClick={() => ConfirmarApagarCliente(cliente.cod_pessoa)}><img className="acoes-grid-pex" src={assets.BotaoApagar} alt="Apagar" /></Link>
                    </td>
                  </tr>
                ))
                
              }
            
            </tbody>

          </table>
        }


        {
          <Modal 
            isOpen={mostrarDelete} 
            onRequestClose={() => setMostrarDelete(false)} 
            contentLabel="Confirmação de Ação" 
            className="modal" 
            overlayClassName="modal-overlay" 
          >
           {/* <div className="prompt-delete">  */}
              <p>Tem certeza que deseja apagar esse cliente?</p> 
              <div>
                <button onClick={() => ApagarCliente(confirmacaoId)} className="yes-button">Sim</button> 
                <button onClick={() => setMostrarDelete(false)} className="no-button">Não</button> 
              </div>
           {/* </div> */}
          </Modal> 
          
          /* Aqui vamos colocar se pode deletar ou nao 
          confirmacaoDelete ?
          <DialogoPex acao="pergunta" onClickSim={() => ApagarCliente(confirmacaoId)} onClickClose={() => setConfirmacaoDelete(false)} posicao={`${scrollPosition}px`}>
              Deseja excluir o cliente selecionado?
          </DialogoPex>

          : null */  
        }

        {
          /* Aqui vamos colocar uma mensagem de erro quando acontecer */  
          confirmacaoErro ?
            <DialogoPex acao="erro" onClickClose={() => setConfirmacaoErro('')}>
              {confirmacaoErro}
            </DialogoPex> : null
        }

        <Paginacao total={totalRegistros} offset={offset} setOffset={setOffset}/>

      </div>

      <Footer/>
    </>  
  )
}

export default Clientes
