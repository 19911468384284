import React from 'react'
import './copiarConfigLoja.css'

const CopiarConfigLoja = () => {
  return (
    <div>
      Copiar configurações de lojas
    </div>
  )
}

export default CopiarConfigLoja
