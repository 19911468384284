import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';


/* Clientes */
function GerarListagemCliente(clientes){
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  
  const TituloRelatorio = [{
      text: "Listagem de Clientes",
      alignment: 'center',
      fontSize: 15,
      bold: true,
      margin: [15, 20, 0, 45]
  }];

  const dados = clientes.map((cliente) => {
      return [
          {text: cliente.cnpj, fontSize: 9},
          {text: cliente.fantasia, fontSize: 9},
          {text: cliente.celular, fontSize: 9},
          {text: cliente.telefone1, fontSize: 9},
          {text: cliente.sexo, fontSize: 9},
          {text: cliente.grupocliente, fontSize: 9},
          {text: cliente.datanascimento, fontSize: 9},
          {text: cliente.codcliente, fontSize: 9},
      ]    
  });
  
  const Detalhes = [{
      table: {
          headerRows: 1,
          widths: ['*', '*'], //as larguras de cada coluna, aqui no caso sao 5. O asterisco se auto organiza
          body: [
              //AQUI TEMOS O HEADER
              [
                  {text: 'CPF/CNPJ', style: 'tableHeader', fontSize: 10},
                  {text: 'Nome', style: 'tableHeader', fontSize: 10},
                  {text: 'Celular', style: 'tableHeader', fontSize: 10},
                  {text: 'Telefone', style: 'tableHeader', fontSize: 10},
                  {text: 'Sexo', style: 'tableHeader', fontSize: 10},
                  {text: 'Grupo', style: 'tableHeader', fontSize: 10},
                  {text: 'Data nascimento', style: 'tableHeader', fontSize: 10},
                  {text: 'ID', style: 'tableHeader', fontSize: 10},
              ],
              ...dados
          ]
      },
      layout: 'lightHorizontalLines'
  }];

  function Rodape(currentPage, pageCount){
      return [
          {
              text: currentPage + ' de ' + pageCount,
              alignment: 'right',
              fontSize: 9,
              margin: [0, 10, 20, 0]
          } ]
  }

  const docDefinicoes = {
      pageSize: 'A4',
      pageMargins: [15, 50, 15, 40], //left, top, right, bottom

      header: [TituloRelatorio],
      content: [Detalhes],
      footer: Rodape
  }

  pdfMake.createPdf(docDefinicoes).download();
}

export {GerarListagemCliente}