import React from 'react'
import './regraRoyalties.css'

const RegraRoyalties = () => {
  return (
    <div>
      Regra de royalties
    </div>
  )
}

export default RegraRoyalties
