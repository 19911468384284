import React from 'react'
import './resumoCFOP.css'

const RelResumoCFOP = () => {
  return (
    <div>
      resumoCFOP
    </div>
  )
}

export default RelResumoCFOP
