import React from 'react'
import './rankingProdutos.css';

const RankingProdutos = () => {
  return (
    <div>
      rankingProdutos
    </div>
  )
}

export default RankingProdutos
