import React from 'react'
import './emissaoNfe.css'

const EmissaoNfe = () => {
  return (
    <div>
      Emissao de nota fiscal
    </div>
  )
}

export default EmissaoNfe
