import React from 'react'
import './modelo.css'

const Modelo = () => {
  return (
    <div>
      modelo
    </div>
  )
}

export default Modelo
