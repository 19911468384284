import React from 'react'
import './modeloDRE.css'

const ModeloDRE = () => {
  return (
    <div>
      modeloDRE
    </div>
  )
}

export default ModeloDRE
