import React from 'react'
import './consultaDfe.css'

const ConsultaDfe = () => {
  return (
    <div>
      Consulta de DFes
    </div>
  )
}

export default ConsultaDfe
