import React from 'react'
import './analiseDivergenciaBalanco.css'

const AnaliseDivergenciaBalanco = () => {
  return (
    <div>
      analiseDivergenciaBalanco
    </div>
  )
}

export default AnaliseDivergenciaBalanco
