import FundoLogin from './fundo_login.png'
import FundoLogin2 from './fundo_login.png'
import FundoLogin3 from './fundo_login.png'
import Logo from './logo_sm_120.png'
import LogoNome from './SuiteMaximus.png'
import LogoPDF from './pdf.png'
import Pesquisar from './pesquisar.png';
import BotaoAlterar from './alterar.png';
import BotaoApagar from './apagar.png';

export const assets = {
  FundoLogin, FundoLogin2, FundoLogin3, Logo, LogoNome, LogoPDF, Pesquisar, BotaoAlterar, BotaoApagar
}