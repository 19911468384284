import React from 'react'
import './acompanhamentoDiarioVenda.css'

const RelAcompanhamentoDiarioVenda = () => {
  return (
    <div>
      acompanhamentoDiarioVenda
    </div>
  )
}

export default RelAcompanhamentoDiarioVenda
