import React from 'react'
import './artigo.css'

const Artigo = () => {
  return (
    <div>
      artigo
    </div>
  )
}

export default Artigo
