import React from 'react'
import './analiseGeralLoja.css'

const RelAnaliseGeralLoja = () => {
  return (
    <div>
      analiseGeralLoja
    </div>
  )
}

export default RelAnaliseGeralLoja
