import React from 'react'
import './extratoVendas.css'

const RelExtratoVendas = () => {
  return (
    <div>
      extratoVendas
    </div>
  )
}

export default RelExtratoVendas
