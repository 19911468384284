import React from 'react'
import './planoFinanceiro.css'

const PlanoFinanceiro = () => {
  return (
    <div>
      Plano financeiro
    </div>
  )
}

export default PlanoFinanceiro
