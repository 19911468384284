import React from 'react'
import './avisoDebito.css'

const AvisoDebito = () => {
  return (
    <div>
      avisoDebito
    </div>
  )
}

export default AvisoDebito
