import React from 'react'
import './especieVolume.css'

const EspecieVolume = () => {
  return (
    <div>
      especieVolume
    </div>
  )
}

export default EspecieVolume
