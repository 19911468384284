import React from 'react'
import './dashboard.css';

const DashboardCRM = () => {
  return (
    <div>
      Dashboards de CRM
    </div>
  )
}

export default DashboardCRM
