import React from 'react'
import './rankingVendedor.css'

const RelRankingVendedor = () => {
  return (
    <div>
      rankingVendedor
    </div>
  )
}

export default RelRankingVendedor
