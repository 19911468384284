import React from 'react'
import './clienteCompras.css';

const RelClienteCompras = () => {
  return (
    <div>
         clienteCompras
    </div>
  )
}

export default RelClienteCompras
