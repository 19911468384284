import React from 'react'
import './header.css'
import { Link } from 'react-router-dom'
import { FaCaretDown } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { useSMContext } from '../../contexto/contexto';

const Header = () => {

  const {nomeUsuario, descricaoArtigoPlural, descricaoMaterialPlural, descricaoModeloPlural, 
         descricaoLinhaPlural, descricaoColecaoPlural, descricaoGrupoProdutoPlural} = useSMContext(); 

  return (<>
    <header>
      
      <div className="container">
        <input type="checkbox" name="" id="check" />

        <div className="logo-container">
          <h3 className="logo">Suite<span>Maximus</span></h3>
        </div>

        <div className="nav-btn">

          {/* Agora vem o menu */}
          <div className="nav-links">
            
            <ul>
              <li className="nav-link">
                <Link to="/home">Início</Link>
              </li>
              
              {/* Administração */}  
              <li className="nav-link">
                <Link to="#">Administração<FaCaretDown className='caret-menu' /></Link>
                <div className="dropdown">
                  <ul>
                    <li className="dropdown-link">
                      <Link href="#">Cadastros<FaCaretDown className='caret-menu' /></Link>
                      <div className="dropdown second">
                        
                        <ul>
                          <li className="dropdown-link">
                            <Link to="/admin/cad/franqueados">Franqueados</Link>
                          </li>
      
                          <li className="dropdown-link">
                            <Link to="/admin/cad/consultores">Consultores</Link>
                          </li>
      
                          <li className="dropdown-link">
                            <Link to="/admin/cad/lojas">Lojas</Link>
                          </li>
      
                          <li className="dropdown-link">
                            <Link to="/admin/cad/grupo_lojas">Grupos de lojas</Link>
                          </li>
      
                          <li className="separador-menu">
                          </li>

                          <li className="dropdown-link">
                            <Link to="/admin/cad/colaboradores">Colaboradores</Link>
                          </li>

                          <li className="dropdown-link">
                            <Link to="/admin/cad/fornecedores">Fornecedores</Link>
                          </li>

                          <li className="dropdown-link">
                            <Link to="/admin/cad/transportadoras">Transportadoras</Link>
                          </li>

                          <li className="separador-menu">
                          </li>

                          <li className="dropdown-link">
                            <Link to="/admin/cad/regra_negocio">Regras de negócio</Link>
                          </li>

                          <li className="dropdown-link">
                            <Link to="/admin/cad/regra_comissao">Regras de comisão</Link>
                          </li>

                          <li className="separador-menu">
                          </li>

                          <li className="dropdown-link">
                            <Link to="/admin/cad/feriados">Feriados</Link>
                          </li>

                          <li className="separador-menu">
                          </li>

                          <li className="dropdown-link">
                            <Link to="/admin/cad/modelo_etiqueta">Modelos de etiquetas</Link>
                          </li>

                          <li className="dropdown-link">
                            <Link to="/admin/cad/permissoes">Permissões</Link>
                          </li>
                        </ul>
                      
                      </div>
                    </li>

                    <li className="separador-menu">
                    </li>

                    <li className="dropdown-link">
                      <Link href="#">Consultas<FaCaretDown className='caret-menu' /></Link>
                      <div className="dropdown second">
                        
                        <ul>
                          <li className="dropdown-link">
                            <Link to="/admin/rel/colaborador_loja">Colaboradores por lojas</Link>
                          </li>
                        </ul>
                      
                      </div>
                    </li>

                    <li className="separador-menu">
                    </li>

                    <li className="dropdown-link">
                      <Link href="#">Movimentação<FaCaretDown className='caret-menu' /></Link>
                      <div className="dropdown second">
                        
                        <ul>
                          <li className="dropdown-link">
                            <Link to="/admin/mov/copiar_config_loja">Copiar configurações de lojas</Link>
                          </li>
      
                          <li className="separador-menu">
                          </li>
      
                          <li className="dropdown-link">
                            <Link to="/admin/mov/agendamento">Agendamento</Link>
                          </li>
                        </ul>
                      
                      </div>
                    </li>
                  </ul>
                </div>
              </li>

              {/* Produtos */}  
              <li className="nav-link">
                <Link to="#">Produtos<FaCaretDown /></Link>
                <div className="dropdown">
                  <ul>
                  <li className="dropdown-link">
                      <Link href="#">Cadastros<FaCaretDown className='caret-menu' /></Link>
                      <div className="dropdown second">
                        
                        <ul>
                          <li className="dropdown-link">
                            <Link to="/prod/cad/produtos">Produtos</Link>
                          </li>

                          <li className="dropdown-link">
                            <Link to="/prod/cad/kits">Kits</Link>
                          </li>

                          <li className="separador-menu">
                          </li>

                          <li className="dropdown-link">
                            <Link to="/prod/cad/grade_tamanho">Grades de tamanho</Link>
                          </li>

                          <li className="dropdown-link">
                            <Link to="/prod/cad/tabela_preco">Tabelas de preços</Link>
                          </li>

                          <li className="dropdown-link">
                            <Link to="/prod/cad/unidade_medida">Unidades de medidas</Link>
                          </li>

                          <li className="dropdown-link">
                            <Link to="/prod/cad/cores">Cores</Link>
                          </li>

                          <li className="separador-menu">
                          </li>

                          <li className="dropdown-link">
                            <Link to="#">Elementos de controle<FaCaretDown className='caret-menu' /></Link>
                            <div className="dropdown second">
                              <ul>
                              <li className="dropdown-link">
                                  <a href="/prod/cad/artigo">{descricaoArtigoPlural}</a>
                                </li>
                                <li className="dropdown-link">
                                  <a href="/prod/cad/material">{descricaoMaterialPlural}</a>
                                </li>
                                <li className="separador-menu">
                                </li>
                                <li className="dropdown-link">
                                  <a href="/prod/cad/colecao">{descricaoColecaoPlural}</a>
                                </li>
                                <li className="dropdown-link">
                                  <a href="/prod/cad/modelo">{descricaoModeloPlural}</a>
                                </li>
                                <li className="separador-menu">
                                </li>
                                <li className="dropdown-link">
                                  <a href="/prod/cad/linha">{descricaoLinhaPlural}</a>
                                </li>
                                <li className="dropdown-link">
                                  <a href="/prod/cad/grupo_produto">{descricaoGrupoProdutoPlural}</a>
                                </li>
                              </ul>
                            </div>
                          </li>

                        </ul>
                      
                      </div>
                    </li>

                    <li className="separador-menu">
                    </li>

                    <li className="dropdown-link">
                      <Link href="#">Consultas<FaCaretDown className='caret-menu' /></Link>
                      <div className="dropdown second">
                        
                        <ul>
                          <li className="dropdown-link">
                            <Link to="/prod/rel/geral_produto">Geral de produtos</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/prod/rel/extrato_produto">Extrato de produtos</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/prod/rel/rastreio_produto">Rastreio de produtos</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/prod/rel/troca_devolucao">Trocas e devoluções</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/prod/rel/entrada_saida_mes_mes">Entrada e saída mês a mês</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/prod/rel/alteracao_preco">Alterações de preços</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/prod/rel/pedidos">Pedidos</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/prod/rel/giro_produto">Giro de produtos</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/prod/rel/ranking_produto">Ranking de produtos</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/prod/rel/dashboard">Dashboard</Link>
                          </li>
                        </ul>
                      
                      </div>
                    </li>

                    <li className="separador-menu">
                    </li>

                    <li className="dropdown-link">
                      <Link href="#">Movimentação<FaCaretDown className='caret-menu' /></Link>
                      <div className="dropdown second">
                        
                        <ul>
                          <li className="dropdown-link">
                            <Link to="/prod/mov/cadastro_produto_xml">Cadastrar e dar entrada de produtos via XML</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/prod/mov/pedido_loja">Pedidos para lojas</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/prod/mov/pedido_ranking_produto">Pedidos a partir de ranking de produtos</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/prod/mov/receber_pedido">Receber pedidos</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/prod/mov/integrar_produto_commerce">Integrar produtos e-commerce</Link>
                          </li>
                        </ul>
                      
                      </div>
                    </li>

                    <li className="separador-menu">
                    </li>

                    <li className="dropdown-link">
                      <Link href="#">Inventários<FaCaretDown className='caret-menu' /></Link>
                      <div className="dropdown second">
                        
                        <ul>
                          <li className="dropdown-link">
                            <Link to="/prod/inv/entrar_balanco">Entrar em modo de balanço</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/prod/inv/gerar_arquivo_coletor">Gerar arquivo p/ coletor</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/prod/inv/receber_arquivo_coletor">Receber arquivo de coletor</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/prod/inv/limpar_dados_balanco">Limpar dados de balanço</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/prod/inv/desistir_balanco">Desistir de balanço</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/prod/inv/concluir_balanco">Concluir balanço</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/prod/inv/analise_divergencia_balanco">Análise de divergência de balanço</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/prod/inv/apuracao_inventario">Apuração de inventário</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/prod/inv/inventario_rotativo">Inventário rotativo</Link>
                          </li>

                        </ul>
                      
                      </div>
                    </li>

                    <li className="separador-menu">
                    </li>

                    <li className="dropdown-link">
                      <Link to="/prod/emissao_etiqueta">Emissão de etiquetas</Link>
                    </li>

                  </ul>
                </div>
              </li>

              {/* Financeiro */}  
              <li className="nav-link">
                <Link to="#">Financeiro<FaCaretDown /></Link>
                <div className="dropdown">
                  <ul>
                  <li className="dropdown-link">
                      <Link href="#">Cadastros<FaCaretDown className='caret-menu' /></Link>
                      <div className="dropdown second">
                        
                        <ul>
                          <li className="dropdown-link">
                            <Link to="/financ/cad/plano_financeiro">Planos financeiros</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/cad/natureza">Naturezas</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/cad/centro_custo">Centros de custo</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/cad/conta_corrente">Contas correntes</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/cad/pequeno_caixa">Caixas</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/cad/plano_cartao">Planos de cartões</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/cad/tipo_documento">Tipos de documentos</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/cad/modelo_rateio">Modelos de rateios</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/cad/aviso_debito">Aviso de débito</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/cad/modelo_dre">Modelos de DRE</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/cad/portador">Portadores</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/cad/modelo_sangria">Modelos de sangria</Link>
                          </li>
                        </ul>
                      
                      </div>
                    </li>

                    <li className="separador-menu">
                    </li>

                    <li className="dropdown-link">
                      <Link href="#">Consultas<FaCaretDown className='caret-menu' /></Link>
                      <div className="dropdown second">
                        
                        <ul>
                          <li className="dropdown-link">
                            <Link to="/financ/rel/dre_financeiro">DRE financeiro</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/rel/titulo_pagar">Títulos a pagar</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/rel/titulo_receber">Títulos a receber</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/rel/fluxo_pequeno_caixa">Fluxo de pequeno caixa</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/rel/fluxo_conta_corrente">Fluxo de conta corrente</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/rel/fluxo_caixa_empresa">Fluxo de caixa da empresa</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/rel/fluxo_caixa_resumido">Fluxo de caixa resumido</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/rel/cartoes">Cartões</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/rel/mapa_cartoes">Mapa de cartões</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/rel/promissorias">Promissórias</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/rel/dashboard">Dashboard</Link>
                          </li>
                        </ul>
                      
                      </div>
                    </li>

                    <li className="separador-menu">
                    </li>

                    <li className="dropdown-link">
                      <Link href="#">Movimentação<FaCaretDown className='caret-menu' /></Link>
                      <div className="dropdown second">
                        
                        <ul>
                        <li className="dropdown-link">
                            <Link to="/financ/mov/titulo_pagar">Títulos a pagar</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/mov/titulo_receber">Títulos a receber</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/mov/lancamento_bancario">Lançamentos bancários</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/mov/lancamento_caixa">Lançamentos de caixa</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/mov/transferencias">Transferências</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/mov/promissorias">Promissórias</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/mov/conciliacao_cartoes">Conciliação de cartões</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/mov/conciliacao_bancaria">Conciliação bancária</Link>
                          </li>
                        </ul>
                      
                      </div>
                    </li>

                    <li className="separador-menu">
                    </li>

                    <li className="dropdown-link">
                      <Link href="#">Royalties<FaCaretDown className='caret-menu' /></Link>
                      <div className="dropdown second">
                        
                        <ul>
                          <li className="dropdown-link">
                            <Link to="/financ/roy/regra_royalties">Regra de royalties</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/roy/rel_royalties_importado">Relatório royalties importados</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/roy/comissao_fornecedor">Comissão de fornecedores</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/financ/roy/devolucao_loja">Relatório de devoluções por lojas</Link>
                          </li>
                        </ul>
                      
                      </div>
                    </li>


                  </ul>
                </div>
              </li>

              {/* CRM */}  
              <li className="nav-link">
                <Link to="#">CRM<FaCaretDown /></Link>
                <div className="dropdown">
                  <ul>
                  <li className="dropdown-link">
                      <Link href="#">Cadastros<FaCaretDown className='caret-menu' /></Link>
                      <div className="dropdown second">
                        
                        <ul>
                        <li className="dropdown-link">
                            <Link to="/crm/cad/clientes">Clientes</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/crm/cad/grupo_cliente">Grupos de clientes</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/crm/cad/plano_pontuacao">Planos de pontuação</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/crm/cad/acao_sms">Ações de SMS</Link>
                          </li>
                        </ul>
                      
                      </div>
                    </li>

                    <li className="separador-menu">
                    </li>

                    <li className="dropdown-link">
                      <Link href="#">Consultas<FaCaretDown className='caret-menu' /></Link>
                      <div className="dropdown second">
                        
                        <ul>
                          <li className="dropdown-link">
                            <Link to="/crm/rel/dashboard">Dashboard</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/crm/rel/clientes_compra">Clientes com compras</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/crm/rel/aniversariantes">Aniversariantes</Link>
                          </li>
                        </ul>
                      
                      </div>
                    </li>

                  </ul>
                </div>
              </li>

              {/* Vendas */}  
              <li className="nav-link">
                <Link to="#">Vendas<FaCaretDown /></Link>
                <div className="dropdown">
                  <ul>
                  <li className="dropdown-link">
                      <Link href="#">Cadastros<FaCaretDown className='caret-menu' /></Link>
                      <div className="dropdown second">
                        
                        <ul>
                          <li className="dropdown-link">
                            <Link to="/vendas/cad/vale_presente">Vale presentes</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/vendas/cad/motivo_nao_conversao">Motivos de não conversão</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/vendas/cad/motivo_troca_devolucao">Motivos de trocas e devoluções</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/vendas/cad/motivo_cancelamento">Motivos de cancelamento</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/vendas/cad/promocao">Promoções</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/vendas/cad/meta_loja">Metas x Lojas</Link>
                          </li>
                        </ul>
                      
                      </div>
                    </li>

                    <li className="separador-menu">
                    </li>

                    <li className="dropdown-link">
                      <Link href="#">Consultas<FaCaretDown className='caret-menu' /></Link>
                      <div className="dropdown second">
                        
                        <ul>
                          <li className="dropdown-link">
                            <Link to="/vendas/rel/consulta_atendimentos">Consulta de atendimentos</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/vendas/rel/conferencia_caixa">Conferência de caixa</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/vendas/rel/divergencia_caixa_cego">Divergência de caixa cego</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/vendas/rel/ranking_vendedor">Ranking de vendedores</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/vendas/rel/ranking_fornecedor">Ranking de fornecedores</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/vendas/rel/ranking_fornecedor">Extrato de vendas</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/vendas/rel/comparativo_periodico_loja">Comparativo periódico de lojas</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/vendas/rel/venda_elemento_controle">Vendas por elementos de controle</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/vendas/rel/acompanhamento_diario_venda">Acompanhamento diário de vendas</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/vendas/rel/meta_venda">Metas x Vendas</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/vendas/rel/produto_sem_venda">Produtos s/ venda no período</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/vendas/rel/sangria_suprimento">Sangrias x Suprimentos</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/vendas/rel/analise_geral_loja">Análise geral de lojas</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/vendas/rel/dashboard">Dashboard</Link>
                          </li>
                        </ul>
                      
                      </div>
                    </li>

                    <li className="separador-menu">
                    </li>

                    <li className="dropdown-link">
                      <Link href="#">Movimentação<FaCaretDown className='caret-menu' /></Link>
                      <div className="dropdown second">
                        
                        <ul>
                          <li className="dropdown-link">
                            <Link to="/vendas/mov/emissao_nfe">Emissão de NF-e de vendas</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/vendas/mov/cancelar_nfe_vendas">Cancelar NF-e de vendas</Link>
                          </li>
                        </ul>
                      
                      </div>
                    </li>

                    <li className="separador-menu">
                    </li>

                    <li className="dropdown-link">
                      <Link href="#">Eventos<FaCaretDown className='caret-menu' /></Link>
                      <div className="dropdown second">
                        
                        <ul>
                          <li className="dropdown-link">
                            <Link to="/vendas/eventos/eventos">Cadastro de eventos</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/vendas/eventos/rel_pedido_distribuicao">Relatório de pedidos para distribuição</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/vendas/eventos/acompanhamento_evento">Acompanhamento de evento</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/vendas/eventos/rel_gerencial_evento">Relatório gerencial de eventos</Link>
                          </li>
                        </ul>
                      
                      </div>
                    </li>


                  </ul>
                </div>
              </li>

              {/* Fiscal */}  
              <li className="nav-link">
                <Link to="#">Fiscal<FaCaretDown /></Link>
                <div className="dropdown">
                  <ul>
                  <li className="dropdown-link">
                      <Link href="#">Cadastros<FaCaretDown className='caret-menu' /></Link>
                      <div className="dropdown second">
                        
                        <ul>
                          <li className="dropdown-link">
                            <Link to="/fiscal/cad/contadores">Contadores</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/fiscal/cad/especie_volume">Espécies de volume</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/fiscal/cad/aliquota_icms_cfop">Alíquotas de ICMS por CFOP</Link>
                          </li>
                        </ul>
                      
                      </div>
                    </li>

                    <li className="separador-menu">
                    </li>

                    <li className="dropdown-link">
                      <Link href="#">Consultas<FaCaretDown className='caret-menu' /></Link>
                      <div className="dropdown second">
                        
                        <ul>
                          <li className="dropdown-link">
                            <Link to="/fiscal/rel/consulta_dfe">Consulta de notas fiscais</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/fiscal/rel/livro_registro_entrada">Livro registro de entrada</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/fiscal/rel/livro_registro_saida">Livro registro de saída</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/fiscal/rel/livro_apuracao_icms">Livro de apuração de ICMS</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/fiscal/rel/relatorio_contabil_estoque">Relatório contábil de estoque</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/fiscal/rel/geral_icms">Geral de ICMS</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/fiscal/rel/resumo_cfop">Resumo de CFOP</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/fiscal/rel/possiveis_duplicidades">Possíveis duplicidades</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/fiscal/rel/cmv_periodo">CMV no período</Link>
                          </li>
                        </ul>
                      
                      </div>
                    </li>

                    <li className="separador-menu">
                    </li>

                    <li className="dropdown-link">
                      <Link href="#">Movimentação<FaCaretDown className='caret-menu' /></Link>
                      <div className="dropdown second">
                        
                        <ul>
                          <li className="dropdown-link">
                            <Link to="/fiscal/mov/emissao_nfe">Emissão de NF-e</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/fiscal/mov/manutencao_nfe">Manutenção de notas fiscais</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/fiscal/mov/inutilizar_numero_nfe">Inutilizar número de NF-e</Link>
                          </li>
                          <li className="separador-menu">
                          </li>
                          <li className="dropdown-link">
                            <Link to="/fiscal/mov/ajuste_apuracao_icms">Informações de ajustes de apuração de ICMS</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/fiscal/mov/ajuste_apuracao_icms_st">Informações de ajustes de apuração de ICMS ST</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/fiscal/mov/obrigacoes_icms_recolher">Obrigações de ICMS a recolher</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/fiscal/mov/obrigacoes_icms_recolher_st">Obrigações de ICMS ST a recolher</Link>
                          </li>
                          <li className="dropdown-link">
                            <Link to="/fiscal/mov/gerar_arquivo_sped">Gerar arquivo SPED</Link>
                          </li>
                        </ul>
                      
                      </div>
                    </li>


                  </ul>
                </div>
              </li>

            </ul>

          </div>
          
          
          <ul className='btn-grupo'>
            <button type="button" className="btn btn-outline-light me-3 dropdown-toggle mt-2 ajuste-login" data-bs-toggle="dropdown" aria-expanded="false" >
              <FaUser className='mb-1 me-2'/> <span className='me-2'>{nomeUsuario ? nomeUsuario.slice(0, nomeUsuario.indexOf(' ')) : "Entrar"}</span>
            </button>

            <ul className='dropdown-menu dropdown-menu-end'>
              <li><Link to="#" className='dropdown-item'>Meu Perfil</Link></li>
              <li><Link to="#" className='dropdown-item'>Parâmetros</Link></li>
              <li><hr className='dropdown-divider'/></li>
              <li><Link to="#" className='dropdown-item'>Sair</Link></li>
            </ul>
          </ul>
        </div>

        <div className="hamburger-menu-container">
          <div className="hamburger-menu">
            <div></div>
          </div>
        </div>
      </div>

    </header>

    </>
  )
}

export default Header
