import React from 'react'
import Globais from '../../globais'
import './paginacao.css';

const MAX_ITEMS = 9;
const MAX_LEFT = (MAX_ITEMS - 1) / 2;


const Paginacao = ({total, offset, setOffset}) => {
  
  const current = offset ? (offset / Globais.QTD_ITEM_PAGINA) + 1 : 1;
  const pages = Math.ceil(total / Globais.QTD_ITEM_PAGINA); 
  const maxFirst = Math.max(pages - (MAX_ITEMS - 1), 1);
  const first = Math.min(Math.max(current - MAX_LEFT, 1), maxFirst);

  function onPageChange(page){
    setOffset((page - 1) * Globais.QTD_ITEM_PAGINA)
    }

/*  console.log('offset ' + offset)
  console.log(current); */

  return (
    <ul className='pagination'>
      <li>
        <button onClick={() => onPageChange(current-1)} 
                disabled={current===1} className='page-antpro'>
          Anterior
        </button>
      </li>
      {

        /* Aqui eu crio um array unidefined e transformo ele em uma numeração  */
        Array.from({length: Math.min(MAX_ITEMS, pages)})
          .map((_, index) => index + first)
          .map((page) => (
          <li key={page}>
            <button className={page === current ? 'pagination__item--active page-numero' : 'page-numero'} onClick={() => onPageChange(page)}>{page}</button>
          </li>
        ))} 
      <li>
        <button onClick={() => onPageChange(current + 1)} disabled={current===pages} className='page-antpro'>Próximo</button>
      </li>
    </ul>
  )
}

export default Paginacao
