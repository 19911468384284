import React from 'react'
import './fluxoCaixaEmpresa.css'

const RelFluxoCaixaEmpresa = () => {
  return (
    <div>
      fluxoCaixaEmpresa
    </div>
  )
}

export default RelFluxoCaixaEmpresa
