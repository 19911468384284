import React from 'react'
import './relPedidoDistribuicao.css';

const RelPedidoDistribuicao = () => {
  return (
    <div>
      RelPedidoDistribuicao
    </div>
  )
}

export default RelPedidoDistribuicao
