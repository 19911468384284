import React from 'react'
import './concluirBalanco.css'

const ConcluirBalanco = () => {
  return (
    <div>
      concluirBalanco
    </div>
  )
}

export default ConcluirBalanco
