import React, {useState} from 'react'
import './login.css';
import { assets } from '../../assets/assets';
import { Navigate } from 'react-router-dom'
import { useSMContext } from '../../contexto/contexto';
import Loading from './../../componentes/loading/loading';
import axios from 'axios';
import Globais from '../../globais';

const Login = () => {

  const {setHostServidor, setPortaServidor, setCodClientePex, setCodUsuario, setNomeUsuario, 
         setDescricaoArtigo, setDescricaoArtigoPlural, setLoginUsuario, 
         setDescricaoMaterial, setDescricaoMaterialPlural,
         setDescricaoModelo, setDescricaoModeloPlural,
         setDescricaoLinha, setDescricaoLinhaPlural,
         setDescricaoColecao, setDescricaoColecaoPlural,
         setDescricaoGrupoProduto, setDescricaoGrupoProdutoPlural,
        setCodFranqueado, setLojas} = useSMContext(); 

  const [loading, setLoading] = useState(false);
  const [msgErro, setMsgErro] = useState('');
  const [validado, setValidado] = useState('N');
  const [usuario, setUsuario] = useState('');
  const [password, setPassword] = useState('');
  
  const ProcessarLogin = async (e) =>{
    e.preventDefault();
    setLoading(true);

    if ((usuario.length === 0) || (password.length === 0)){
      setValidado("N");
      setMsgErro('Necessário especificar login e senha');
      setLoading(false);
      return;
    }

    try {
      const responseLogin = await axios.get(Globais.IP_PAINEL + "/web/v1/validarLogin", {params: {login: usuario, senha: password, sistema: "SuiteMaximusWeb"}});
    
      if (responseLogin.data.validou){
        try {
          const response = await axios.get('http://' + responseLogin.data.ip_servidor + ':' + responseLogin.data.porta_servidor + "/web/v1/validarLogin", {params: {login: usuario, senha: password, sistema: "SuiteMaximusWeb"}});
          if (response.data.validou){
            setValidado("S");
            setMsgErro("");
            setLoading(false);
            /* Aqui preciso guardar algumas informações importantes */
            setHostServidor(responseLogin.data.ip_servidor);
            setPortaServidor(responseLogin.data.porta_servidor);
            setCodClientePex(response.data.cod_cliente_pex);

            localStorage.setItem('host_servidor', responseLogin.data.ip_servidor);
            localStorage.setItem('porta_servidor', responseLogin.data.porta_servidor);


            setCodUsuario(response.data.cod_usuario);
            setCodFranqueado(response.data.cod_franqueado);
            setLojas(response.data.lojas);
            setNomeUsuario(response.data.nome);
            setDescricaoArtigo(response.data.descricao_artigo); 
            setDescricaoArtigoPlural(response.data.descricao_artigo_plural);
            setDescricaoMaterial(response.data.descricao_material); 
            setDescricaoMaterialPlural(response.data.descricao_material_plural);
            setDescricaoModelo(response.data.descricao_modelo); 
            setDescricaoModeloPlural(response.data.descricao_modelo_plural);
            setDescricaoLinha(response.data.descricao_linha); 
            setDescricaoLinhaPlural(response.data.descricao_linha_plural);
            setDescricaoColecao(response.data.descricao_colecao); 
            setDescricaoColecaoPlural(response.data.descricao_colecao_plural);
            setDescricaoGrupoProduto(response.data.descricao_grupo_produto); 
            setDescricaoGrupoProdutoPlural(response.data.descricao_grupo_produto_plural);
            setLoginUsuario(response.data.login);
   
            /* Salva no storage os dados do usuario */
            localStorage.setItem('cod_usuario_sm', response.data.cod_usuario);
            localStorage.setItem('nome_usuario_sm', response.data.nome);
            localStorage.setItem('login_usuario_sm', response.data.login);
            localStorage.setItem('cod_franqueado', response.data.cod_franqueado);
            localStorage.setItem('cod_cliente_pex', response.data.cod_cliente_pex);

          }else{
            setValidado("N");
            setLoading(false);
            setMsgErro(response.data.msg);
            return;  
            }
        } catch (error) {
          alert(error.message)  
        }
      }else{
        setValidado("N");
        setLoading(false);
        setMsgErro(responseLogin.data.msg);
        return;  
      } 
    } catch (error) {
      setValidado("N");
      setLoading(false);
      alert('Servidor de login: ' + error.message);      
    }
  }

  return (
    <>
    <div className='container-background'>
    <div className="container-login">
      
      <div className='img-box-login'>
        <img src={assets.FundoLogin} alt="" />
      </div>

      <div className='content-box-login'>
        <div className='form-box-login'>
          <img src={assets.Logo} alt="" />
          {
            (msgErro === '') ? 
              <div className="heading">
                <h2>SuiteMaximus</h2>
                <h6>Entre com suas credenciais</h6>
              </div>
              : 
              <span className='showMessage'>{msgErro}</span>
          }

          {loading ? ( <Loading /> ) :
            validado==='S' ? <Navigate to="/home"/> :
              <form>
                <div className='input-box'>
                  <span>Usuário</span>
                  <input type="text" required onChange={(e)=>{setUsuario(e.target.value)}}/>
                </div>

                <div className='input-box'>
                  <span>Senha</span>
                  <input type="password" required onChange={(e)=>{setPassword(e.target.value)}}/>
                </div>

                <div className='input-box'>
                  <input type="submit" value="Entrar no SuiteMaximus" onClick={ProcessarLogin}/>
                </div>
              </form>
          }
        </div>

      </div>
    </div>
    </div>
    </>
  )
}

export default Login
