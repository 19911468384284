import React from 'react'
import './integrarProdutoECommerce.css'

const IntegrarProdutoECommerce = () => {
  return (
    <div>
      integrarProdutoECommerce
    </div>
  )
}

export default IntegrarProdutoECommerce
