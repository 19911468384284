import React from 'react'
import './cores.css'

const Cores = () => {
  return (
    <div>
      cores
    </div>
  )
}

export default Cores
