import React from 'react'
import './produtoSemVenda.css'

const RelProdutoSemVenda = () => {
  return (
    <div>
      produtoSemVenda
    </div>
  )
}

export default RelProdutoSemVenda
