import React from 'react'
import './divergenciaCaixaCego.css'

const RelDivergenciaCaixaCego = () => {
  return (
    <div>
      divergenciaCaixaCego
    </div>
  )
}

export default RelDivergenciaCaixaCego
