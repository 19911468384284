import React from 'react'
import './aliquotaIcmsCfop.css'

const AliquotaIcmsCfop = () => {
  return (
    <div>
      aliquotaIcmsCfop
    </div>
  )
}

export default AliquotaIcmsCfop
