import React from 'react'
import './notFound.css';
import Navbar from '../../componentes/navbar/navbar';

const NotFound = () => {
  return (
    <div>
      <Navbar/>
      Not Found
    </div>
  )
}

export default NotFound
