import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { SMProvider } from './contexto/contexto';
import Rotas from './rotas/rotas';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <SMProvider>
    <Rotas />
  </SMProvider>
);
