import React from 'react'
import './dialogo.css'

/*
    Ações pode ser: confirmacao, erro, atencao, pergunta
*/



const DialogoPex = ({id='modal', acao="confirmacao", titulo='', onClickClose = () => {}, onClickSim = () => {}, posicao, children}) => {

  var icone, ClassNameFechar = '';
  var BotaoFechar = 'Fechar';

  if (titulo === ''){
    if (acao === 'pergunta'){
        titulo = 'Responda, por favor';
        BotaoFechar = 'Não';
    }else if (acao === 'erro'){
        titulo = 'Ops! Tivemos um problema'
    }
    else if (acao === 'atencao'){
        titulo = 'Vamos prestar atençã£o nessa informação'
    }
    else{
        titulo = 'Confirme';
    }
  }

  if (acao === 'pergunta'){
    ClassNameFechar = 'btn btn-danger botao-vermelho';
    icone = "fa fa-question-circle fa-3x imagem-modal-pex-pergunta";
  }else if (acao === 'erro'){
    ClassNameFechar = 'btn btn-danger botao-vermelho';
    icone = "fa fa-times-circle fa-3x imagem-modal-pex-erro";
  }
  else if (acao === 'atencao'){
    ClassNameFechar = 'btn btn-warning botao-amarelo'
    icone = "fa fa-exclamation-circle fa-3x imagem-modal-pex-atencao";
  }
  else{
    ClassNameFechar = 'btn btn-primary botao-azul';
    icone = "fa fa-check-circle fa-3x imagem-modal-pex-confirmacao";
  }

  const CliqueForaTela = (e) => {
    if (e.target.id === id) onClickClose();
  }

return (
    <>
      <div id={id} className="modal-pex" onClick={CliqueForaTela}>
        
        <div className="container-modal-pex" style={{ top: `${posicao}px`}}>
          <div className="titulo-modal-pex">{titulo}
            <hr></hr>
          </div>
          
          <div className="conteudo-modal-pex">
            <div className="row espaco-row-pex">
              <div className="col-md-2">
                <i className={icone}></i>
              </div>
              
              <div className="col-md-10">
                {children}
              </div>
            </div>
          </div>
          
          <hr />
          
          {(acao === 'pergunta') ? <button onClick={onClickSim} className="botao-modal-pex botao-azul">Sim</button> : null}   
          <button onClick={onClickClose} className={"botao-modal-pex botao-amarelo " + ClassNameFechar}>{BotaoFechar}</button>

        </div>
      
      </div> 
    </>
  )
}

export default DialogoPex
