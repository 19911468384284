import React from 'react'
import './gradeTamanho.css'

const GradeTamanho = () => {
  return (
    <div>
      gradeTamanho
    </div>
  )
}

export default GradeTamanho
