import React from 'react'
import './rankingFornecedor.css'

const RelRankingFornecedor = () => {
  return (
    <div>
      rankingFornecedor
    </div>
  )
}

export default RelRankingFornecedor
