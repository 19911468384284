import React from 'react'
import './transportadoras.css'

const Transportadoras = () => {
  return (
    <div>
      transportadoras
    </div>
  )
}

export default Transportadoras
