import React from 'react'
import './giroProdutos.css';

const GiroProdutos = () => {
  return (
    <div>
      giroProdutos
    </div>
  )
}

export default GiroProdutos
