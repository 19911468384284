import React from 'react'
import './regraComissao.css'

const RegraComissao = () => {
  return (
    <div>
      RegraComissao
    </div>
  )
}

export default RegraComissao
