import React from 'react'
import './dashboard.css'

const DashboardFinanceiro = () => {
  return (
    <div>
      dashboard
    </div>
  )
}

export default DashboardFinanceiro
