import React from 'react'
import './obrigacoesICMSRecolherST.css'

const ObrigacoesICMSRecolherST = () => {
  return (
    <div>
      obrigacoesICMSRecolherST
    </div>
  )
}

export default ObrigacoesICMSRecolherST
