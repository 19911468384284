import React from 'react'
import './feriados.css'

const Feriados = () => {
  return (
    <div>
      feriados
    </div>
  )
}

export default Feriados
