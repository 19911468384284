import React from 'react'
import './promocoes.css'

const Promocoes = () => {
  return (
    <div>
      promocoes
    </div>
  )
}

export default Promocoes
