import React from 'react'
import './promissorias.css'

const Promissorias = () => {
  return (
    <div>
      promissorias
    </div>
  )
}

export default Promissorias
