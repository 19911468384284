import React from 'react'
import './valePresente.css'

const ValePresente = () => {
  return (
    <div>
      Vale presente
    </div>
  )
}

export default ValePresente
