import React from 'react'
import './modeloRateio.css'

const ModeloRateio = () => {
  return (
    <div>
      modeloRateio
    </div>
  )
}

export default ModeloRateio
