import React from 'react'
import './fluxoContaCorrente.css'

const RelFluxoContaCorrente = () => {
  return (
    <div>
      fluxoContaCorrente
    </div>
  )
}

export default RelFluxoContaCorrente
