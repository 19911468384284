import React from 'react'
import './franqueados.css'

const Franqueados = () => {
  return (
    <div>
      Franqueados
    </div>
  )
}

export default Franqueados
