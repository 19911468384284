import React from 'react'
import './gerarArquivoSPED.css'

const GerarArquivoSPED = () => {
  return (
    <div>
      gerarArquivoSPED
    </div>
  )
}

export default GerarArquivoSPED
