import React from 'react'
import './pequenoCaixa.css'

const PequenoCaixa = () => {
  return (
    <div>
      pequenoCaixa
    </div>
  )
}

export default PequenoCaixa
