import React from 'react'
import './consultores.css'

const Consultores = () => {
  return (
    <div>
      Consultores
    </div>
  )
}

export default Consultores
