import React from 'react'
import './comissaoFornecedor.css'

const ComissaoFornecedor = () => {
  return (
    <div>
      comissaoFornecedor
    </div>
  )
}

export default ComissaoFornecedor
