import React from 'react'
import './gerarArquivoColetor.css'

const GerarArquivoColetor = () => {
  return (
    <div>
      gerarArquivoColetor
    </div>
  )
}

export default GerarArquivoColetor
