import React from 'react'
import './lancamentoCaixa.css'

const LancamentoCaixa = () => {
  return (
    <div>
      lancamentoCaixa
    </div>
  )
}

export default LancamentoCaixa
