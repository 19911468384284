import React from 'react'
import './receberArquivoColetor.css'

const ReceberArquivoColetor = () => {
  return (
    <div>
      receberArquivoColetor
    </div>
  )
}

export default ReceberArquivoColetor
