import React from 'react'
import './grupoLojas.css'

const GrupoLojas = () => {
  return (
    <div>
      Grupo de Lojas
    </div>
  )
}

export default GrupoLojas
