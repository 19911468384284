import React from 'react'
import './obrigacoesICMSRecolher.css'

const ObrigacoesICMSRecolher = () => {
  return (
    <div>
      obrigacoesICMSRecolher
    </div>
  )
}

export default ObrigacoesICMSRecolher
