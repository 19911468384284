import React from 'react'
import './dreFinanceiro.css'

const DreFinanceiro = () => {
  return (
    <div>
      DRE Financeiro
    </div>
  )
}

export default DreFinanceiro
