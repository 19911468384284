import React from 'react'
import './promissorias.css'

const RelPromissorias = () => {
  return (
    <div>
      promissorias
    </div>
  )
}

export default RelPromissorias
