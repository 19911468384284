import React from 'react'
import './linha.css'

const Linha = () => {
  return (
    <div>
      linha
    </div>
  )
}

export default Linha
