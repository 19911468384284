import React from 'react'
import './tituloPagar.css'

const RelTituloPagar = () => {
  return (
    <div>
      tituloPagar
    </div>
  )
}

export default RelTituloPagar
