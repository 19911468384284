import React from 'react'
import './home.css';
import Header from '../../componentes/header/header';

const Home = () => {

  return (<>
      <Header/>
{/* 
      <div className='container-fluid mt-page'>
        <div className='m-2 mt-4 d-flex justify-content-between'>
          <h2>Dashboards</h2>
        </div>
        <div className="row">
        </div>

      </div> */}
    </>
  )
}

export default Home
