import React from 'react'
import './aniversariantes.css';

const RelAniversariantes = () => {
  return (
    <div>
      aniversariantes
    </div>
  )
}

export default RelAniversariantes
