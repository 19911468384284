import React from 'react'
import './natureza.css'

const Natureza = () => {
  return (
    <div>
      natureza
    </div>
  )
}

export default Natureza
