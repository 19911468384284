import React from 'react'
import './possiveisDuplicidades.css'

const RelPossiveisDuplicidades = () => {
  return (
    <div>
      possiveisDuplicidades
    </div>
  )
}

export default RelPossiveisDuplicidades
