import React from 'react'
import './consultaAtendimentos.css'

const ConsultaAtendimentos = () => {
  return (
    <div>
      Consulta de atendimentods
    </div>
  )
}

export default ConsultaAtendimentos
