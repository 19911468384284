import React from 'react'
import './acompanhamentoEvento.css';

const RelAcompanhamentoEvento = () => {
  return (
    <div>
      acompanhamentoEvento
    </div>
  )
}

export default RelAcompanhamentoEvento
