import React from 'react'
import './acaoSMS.css';

const AcaoSMS = () => {
  return (
    <div>
      acaoSMS
    </div>
  )
}

export default AcaoSMS
