import React from 'react'
import './tipoDocumento.css'

const TipoDocumento = () => {
  return (
    <div>
      tipoDocumento
    </div>
  )
}

export default TipoDocumento
